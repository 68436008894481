<template>
  <valid-form :request="add" ref="form">
    <div class="row type">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <custom-input
            v-model="title"
            :label="$i18n.t('setting.task.title') "
            inputName="title"
            rules="required"
        />
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <ValidationProvider :name="$i18n.t('setting.task.description')" rules='required' v-slot="{ errors }">

          <label for="description">{{ $i18n.t('setting.task.description') }}</label>
          <textarea :class="{'input-error':errors[0]}" id="description" name="w3review" rows="4" cols="50"
                    v-model="description"></textarea>
          <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

        </ValidationProvider>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
        <validation-provider ref="dateList" :name="$i18n.t('project.add.timeNotification')"
                             v-slot="{ errors }"
                             class="form-group">
          <label>{{ $i18n.t('project.add.timeNotification') }}</label>
          <v-select
                  dir="rtl"
              :options='dateList'
              v-model='selectedTime'
              @search:blur="getTime"
              :class="{'input-error':errors[0]}"
              label="title"

          >
          </v-select>
          <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

        </validation-provider>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <valid-form >
          <custom-input
              v-model="link"
              rules="url"
              :label="$i18n.t('project.add.link')"
              inputName="link"
          />
        </valid-form>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <div class="file">
          <span class="name">{{ uploading['fileId'].name }}</span>
          <span class="icon icon-close close-upload"></span>
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <p class="upload" v-if="uploading.fileId.state === 0">
          {{ $i18n.t('myCheckList.uploadFile') }}
        </p>
        <p class="btn error" v-if="uploading.fileId.state === -1">
          <span class="icon icon-upload-white"></span>
          {{ $i18n.t('myCheckList.uploadFile') }}
        </p>
        <p class="btn success" v-if="uploading.fileId.state === 2">
          <span class="icon icon-close-upload"></span>
          {{ $i18n.t('myCheckList.uploadFile') }}</p>
        <p class="btn uploading" v-if="uploading.fileId.state === 1">
          <span class="icon icon-load"></span>
          {{ $i18n.t('myCheckList.uploadFile') }}</p>
        <input @change="uploadFile('fileId')" id="fileId" name="fileId"
               ref="fileId" type="file">
        <div class="type-error" v-if="uploading.fileId.state === -1">{{ $i18n.t('edit.errorTypeDocument1') }}</div>

      </div>

    </div>
  </valid-form>

</template>
<script>

import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";

export default {
  name: 'type_two',
  data() {
    return {
      title: '',
      link: '',
      time: '',
      description: '',
      uploading: {
        isUploading: false,
        isValidate: false,
        fileId: {
          state: 0,
          name: null,
          file: null,
        },
      },
      selectedTime: null,
      dateList: [
        {
          title: i18n.t('setting.task.time.time-1'),
          value: '-1',

        },
        {
          title: i18n.t('setting.task.time.time-2'),
          value: '0',
        },
        {
          title: i18n.t('setting.task.time.time-3'),
          value: '1',
        },
        {
          title: i18n.t('setting.task.time.time-4'),
          value: '2',
        },
        {
          title: i18n.t('setting.task.time.time-5'),
          value: '3',
        }, {
          title: i18n.t('setting.task.time.time-6'),
          value: '5',
        }, {
          title: i18n.t('setting.task.time.time-7'),
          value: '7',
        }, {
          title: i18n.t('setting.task.time.time-8'),
          value: '10',
        },
      ],
    };
  },
  computed: {
    parentData() {
      return this.$parent.$parent.$parent;
    },
  },

  methods: {
    getTime() {
      this.time = this.selectedTime.value
    },
    uploadFile(name) {
      let vm = this;
      if (vm.$refs[name].files.length === 0) {
        return
      }
      let type = vm.$refs[name].files[0].type;
      if (this.$refs[name].files.length > 0 && (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||type === 'application/x-rar' ||type === 'application/zip' ||type === 'image/png' ||type === 'application/pdf' || type === 'image/jpg' || type === 'image/jpeg'||type === 'application/vnd.ms-excel'||type === 'application/msword'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        vm.uploading[name].state = 1;
        vm.uploading.isUploading = true;
        this.selectFileToUpload(vm.$refs[name].files, 'document',
            function (response) {
              vm.uploading[name].file = response.data.data;
              vm.uploading[name].state = 2;
              vm.uploading.isUploading = false;
              vm.uploading[name].name = vm.$refs[name].files[0].name;

            }, function () {
              vm.uploading[name].state = -1;
              vm.uploading[name].name = vm.errors.items[0].msg;
              vm.uploading.isUploading = false;
            });

      } else {
        vm.uploading[name].state = -1;
      }
    },
    add() {
      this.$emit('input');
    }
  },
  components: {
    CustomInput, validForm
  }
};
</script>
