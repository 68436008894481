require('./commonJs/bootScript');
import Vue from 'vue';

new Vue({
  router,
  store,
  i18n,

  data() {
    return {
      checkUser: false,
    };

  },
  computed: {
    user() {
      return this.$store.state.auth.userExist;

    },
    employee() {
      return this.$store.state.auth.employeeExist;
    },

  },
  created() {
    this.fetchUser();
    this.$store.dispatch('project/jobTitleList');

  },
  watch: {

    '$store.state.auth.secondRequest'() {
      let routPath = this.$route.path.length;
      if (this.$store.state.auth.secondRequest===2) {

        if ((this.$store.state.auth.userExist && this.$store.state.auth.employeeExist) ||
          (this.$store.state.auth.userExist && !this.$store.state.auth.employeeExist))
        {

          if (this.$route.path.length === 1) {
                  this.$router.push({name: 'myCheckList.index'}).catch(() => {});
                  return;
              }
          if (this.$route.name.startsWith('auth.')) {
            this.$router.push({name: 'myCheckList.index'}).catch(() => {
            });
            return;
          }


        }
        if( !this.$store.state.auth.userExist && this.$store.state.auth.employeeExist){
          if (this.$route.path.length === 1) {
            this.$router.push({name: 'project.index'}).catch(() => {});
            return;
          }
          if (this.$route.name.startsWith('auth.')) {
            this.$router.push({name: 'project.index'}).catch(() => {
            });
            return;
          }
        }
        if( !this.$store.state.auth.userExist && !this.$store.state.auth.employeeExist){

            this.$router.push({name: 'auth.loginInit'}).catch(() => {});
            return;

        }












        // if (this.$store.state.auth.employeeExist) {
        //   if (routPath === 1) {
        //     console.log("project.index");
        //
        //     //   this.$router.push({name: 'project.index'}).catch(() => {});
        //     //   return;
        //   }
        //   if (this.$route.name.startsWith('auth.')) {
        //     console.log("start with auth: project.index");
        //
        //     //   this.$router.push({name: 'project.index'}).catch(() => {});
        //     //   return;
        //
        //   }
        // }
        // if (!this.$store.state.auth.employeeExist &&!this.$store.state.auth.userExist) {
        //   if (routPath === 1) {
        //     console.log("auth.loginInit");
        //
        //     //   this.$router.push({name: 'auth.loginInit'}).catch(() => {});
        //     //   return;
        //   }
        //
        // }
      }
    },

    // '$store.state.auth.userExist'(){
    //   console.log(this.$store.state.auth.userExist)
    //
    // },
    // '$store.state.auth.employeeExist'(){
    //   let loginStateName =this.$store.getters['auth/loginState'];
    //   console.log(this.$store.getters['auth/loginState'])
    //   let routPath=this.$route.path.length;
    //   if(loginStateName==='both' ||loginStateName==='user'){
    //     if(routPath===1){
    //       this.$router.push({name: 'myCheckList.index'}).catch(() => {});
    //     }
    //   }
    //   if(loginStateName==='unAuthorization'){
    //     if (routPath === 1) {
    //       this.$router.push({name: 'auth.loginInit'}).catch(() => {});
    //     }
    //   }
    //   if(loginStateName==='employee'){
    //     if(routPath===1){
    //       this.$router.push({name: 'project.index'}).catch(() => {});
    //     }
    //   }
    //
    // }
  },

  methods: {

    fetchUser() {
      let vm = this;
      vm.$store.dispatch('auth/fetchUser').then((res) => {
      }).catch(() => {

      });
    },
    fetchEmployee() {
      let vm = this;
      vm.$store.dispatch('auth/fetchEmployee').then(() => {
      }).catch(() => {

        // if(this.employeeLogin && this.userLogin){
        //     axios.get ('oauth/adminLogin').then(()=>{
        //     }).catch((error)=> {
        //
        //     });
        //     console.log('zizi')
        //
        // }

      });
    },
  },
  components: {
    auth: require('./components/pages/auth/auth.vue').default,
    loading: require('./components/pages/partials/loading').default,

  },
}).$mount('#app');
