
export default (value,format = 'jYYYY/jM/jD ')  => {
  if (typeof value === 'undefined') {
    return value;
  }
  if (value !== null) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
  } else {
    return '---';

  }


};
