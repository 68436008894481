import VueRouter from 'vue-router'

window.router=new VueRouter ({
  mode: 'history',
  routes: [

    {
      path: '/auth/',
      component: require('../components/pages/auth/auth.vue').default,
      children: [
        {
          path: '/',
          component: require('../components/pages/auth/login/login.vue').default,
          children: [
            {
              path: '/',
              redirect: 'login',
            },
            {
              path: 'login/:mobile?',
              name: 'auth.loginInit',
              component: require(
                  '../components/pages/auth/login/init.vue').default,
            },
            {
              path: 'login/request',
              name: 'auth.loginRequest',
              component: require(
                  '../components/pages/auth/login/request.vue').default,
            },
          ],
        },

        {
          path: '/auth/verify/:username/:otp',
          name: 'auth.verify',
          component: require('../components/pages/auth/login/init.vue').default,
          meta: {standAlone: true},
        },
      ],
    },
    {
      path: '/',
      component: require ('../components/pages/layout/index.vue').default,
      children: [
        {
          path: 'myCheckList/:id?',
          name: 'myCheckList.index',
          component: require ('../components/pages/myCheckList/index.vue').default,
        },
        {
          path: 'setting/',
          name: 'setting.index',
          component: require ('../components/pages/setting/index.vue').default,
        },
        {
          path: 'user/edit',
          name: 'user.edit',
          component: require(
            '../components/pages/myCheckList/edit.vue').default,
        },
        {
          path: 'team/:id?',
          name: 'team.index',
          component: require ('../components/pages/team/index.vue').default,
        },

        {
          path: 'project',
          name: 'project.index',
          component: require('../components/pages/project/index.vue').default,

        },
        {
          path: 'project/:id',
          name: 'project.show',
          component: require(
              '../components/pages/project/show.vue').default,
        },
        {
          path: 'project/edit/:id',
          name: 'project.edit',
          component: require(
              '../components/pages/project/edit.vue').default,
        },

      ],

    },


  ],
  scrollBehavior( to, from, savedPosition ) {
    return {x: 0, y: 0}
  },
})




