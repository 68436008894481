<template>
  <div class="row second">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <validation-provider ref="address" :name="$i18n.t('project.add.defaultList')" rules='required' v-slot="{ errors }"
                           class="form-group">
        <label>{{ $i18n.t('project.add.defaultList') }}</label>
        <v-select
                dir="rtl"
            :options='checkList'
            v-model=selectedCheckList
            @search:blur="getId"
            :class="{'input-error':errors[0]}"
            label="title">
        </v-select>
        <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

      </validation-provider>

    </div>
    <valid-form :request="addTask" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <custom-input
              v-model="newTsk"
              :label="$i18n.t('project.add.newTsk')"
              inputName="newTsk"
              rules="required"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <validation-provider ref="department" :name="$i18n.t('common.department')" rules='required'
                               v-slot="{ errors }" class="form-group">
            <label>{{ $i18n.t('common.department') }}</label>
            <v-select
                    dir="rtl"
                :options='parentData.departmentInfo'
                v-model='parentData.selected'
                @search:blur="parentData.getDepartmentId"
                :class="{'input-error':errors[0]}"
                label="title">
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <div class="col-lg-6 col-md-5 link col-sm-6 col-xs-12">
          <custom-input
              v-model="link"
              :placeholder="$i18n.t('project.add.link')"
              inputName="link"
              rules="require_protocol"
          />
        </div>
        <div class="col-lg-5 col-md-6 upload col-sm-11 col-xs-10 add-task">
          <p class="upload" v-if="uploading.fileId.state === 0">
            {{ $i18n.t('myCheckList.uploadFile') }}
          </p>
          <p class="btn error" v-if="uploading.fileId.state === -1">
            <span class="icon icon-upload-white"></span>
            {{ $i18n.t('myCheckList.uploadFile') }}
          </p>
          <p class="btn success" v-if="uploading.fileId.state === 2">
            <span class="icon icon-close-upload"></span>
            {{ $i18n.t('myCheckList.uploadFile') }}</p>
          <p class="btn uploading" v-if="uploading.fileId.state === 1">
            <span class="icon icon-load"></span>
            {{ $i18n.t('myCheckList.uploadFile') }}</p>
          <input @change="uploadFile('fileId')" id="fileId" name="fileId"
                 ref="fileId" type="file">
          <div class="type-error" v-if="uploading.fileId.state === -1">{{ $i18n.t('edit.errorTypeDocument1') }}</div>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2">
          <button class="gray">
            <span class="icon icon-add"></span>
          </button>

        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="taskList.length>0">
          <div class="box">
            <div class=" row new-task" v-for="task in taskList" :key="task.id">
              <div class="col-md-10 col-lg-10 col-sm-10 col-xs-11 right-align">
                {{ task.title  | strLimit(60) }}
              </div>
              <div class="col-md-2 col-lg-2 col-sm-2 col-xs-1 left-align">
                <div class="delete pointer" @click="deleteTask(task.id )"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </valid-form>
    <valid-form :request="assignByCheckList">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 action-btn">
        <button class="blue">{{ $i18n.t('common.submit') }}</button>
      </div>
    </valid-form>
  </div>

</template>
<script>
import CustomInput from "../../partials/form/customInput.vue";

import validForm from "../../partials/form/valid-form.vue";
import helpers from "../../../../mixin/helpers";

export default {
  name: 'third-step',
  data() {
    return {
      selectedCheckList: null,
      checklistId: [],
      newTsk: '',
      taskInfo: {title: ''}, assetId: '',
      link: '',
      uploading: {
        isUploading: false,
        isValidate: false,
        fileId: {
          state: 0,
          name: null,
          file: null,
        },
      },

    };
  },


  computed: {
    parentData() {
      return this.$parent.$parent.$parent.$parent
    },
    taskList() {
      let task = _.filter(this.$store.state.task.list, ['type_id', 1])
      return task

    },
    checkList() {
      let list = _.filter(this.$store.state.task.checkList, ['is_personal', 1])
      return _.map(list, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },

  },

  created() {
    this.$store.dispatch('task/taskList')

  },
  methods: {
    getId() {
      let vm = this;
      vm.checklistId = vm.selectedCheckList.id;
    },
    projectList() {
      this.$store.dispatch('project/userList')
    },
    assignByCheckList() {
      let vm = this;
      if (vm.selectedCheckList) {
        axios.post('emp/assignTask/byCheckList', {
          'checklist_id': [vm.checklistId],
          'user_id': vm.parentData.id,
        }).then(() => {
        }).catch((err) => {
          vm.handleApiError(err)
        })
      }
      vm.$store.commit('changeModalStatus', false);
      vm.projectList()

    },
    addTask() {
      let vm = this;
      axios.post('emp/tasks', {
        'title': vm.newTsk,
        'description': vm.newTsk,
        'asset_id': vm.assetId,
        'type_id': '1',
        'department_id': vm.parentData.department_id,
        'checklist_id': '',
        'owner_user_id': vm.parentData.user_id,
      }).then((response) => {
        this.$store.dispatch('task/taskList')
        axios.post('emp/assignTask', {
          'task_id': response.data.data.id,
          'user_id': vm.parentData.id,
        }).then(() => {
        }).catch((err) => {
          vm.handleApiError(err)
        })
      }).catch((err) => {
        vm.handleApiError(err)
      })
    },
    uploadFile(name) {
      let vm = this;
      if (vm.$refs[name].files.length === 0) {
        return
      }
      let type = vm.$refs[name].files[0].type;
      if (this.$refs[name].files.length > 0 && (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||type === 'application/x-rar' ||type === 'application/zip' ||type === 'image/png' ||type === 'application/pdf' || type === 'image/jpg' || type === 'image/jpeg'||type === 'application/vnd.ms-excel'||type === 'application/msword'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        vm.uploading[name].state = 1;
        vm.uploading.isUploading = true;
        this.selectFileToUpload(vm.$refs[name].files, 'document',
            function (response) {
              vm.uploading[name].file = response.data.data;
              vm.uploading[name].state = 2;
              vm.uploading.isUploading = false;
              vm.uploading[name].name = vm.$refs[name].files[0].name;

            }, function () {
              vm.uploading[name].state = -1;
              vm.uploading[name].name = vm.errors.items[0].msg;
              vm.uploading.isUploading = false;
            });

      } else {
        vm.uploading[name].state = -1;
      }
    },
    deleteTask(id) {
      let vm = this;
      let uniqId = id;
      axios.delete('emp/tasks/' + uniqId).then(() => {
        this.$store.dispatch('task/taskList')
        vm.$toast.success({title: vm.$i18n.t('project.successRemoveTask')});
      }).catch((err) => {
        vm.handleApiError(err)
      });

    },


  },
  mixins: [helpers],

  components: {
    validForm, CustomInput
  }
};
</script>
