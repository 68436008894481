<template>
  <div class="welcome">
    <div class="col-lg-5 col-md-6 col-xs-12 col-sm-8">
      <valid-form :request="add" slot="body" ref="form" >
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <custom-input
              v-model="title"
              :label="$i18n.t('setting.welcomeComponent.title') "
              inputName="title"
              rules="required"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <label for="content">{{ $i18n.t('setting.welcomeComponent.content') }}</label>
          <textarea id="content" name="w3review" rows="4" cols="50" v-model="content"></textarea>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="upload-box">

            <p class=" btn blue" v-if="uploading.selfie_img.state === 0">
              <span class="icon icon-upload-white"></span>

              {{ $i18n.t('setting.welcomeComponent.uploadPicture') }}
            </p>
            <p class=" btn error" v-if="uploading.selfie_img.state === -1">
              <span class="icon icon-upload-white"></span>
              {{ $i18n.t('myCheckList.uploadFile') }}
            </p>
            <p class="btn success" v-if="uploading.selfie_img.state === 2">
              <span class="icon icon-close-upload"></span>
              {{ $i18n.t('myCheckList.uploadFile') }}</p>
            <p class="btn uploading" v-if="uploading.selfie_img.state === 1">
              <span class="icon icon-load"></span>
              {{ $i18n.t('myCheckList.uploadFile') }}</p>
            <input @change="uploadFile('selfie_img')" id="selfie_img" name="selfie_img" ref="selfie_img"
                   type="file">
            <p class="type-error" v-if="uploading.selfie_img.state === -1">{{$i18n.t('edit.errorTypeDocument')}}</p>
          </div>
          <div class="divider"></div>

        </div>




      <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <p class="heading-notify">
            {{ $i18n.t('setting.welcomeComponent.notify') }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
          <validation-provider ref="dateList" :name="$i18n.t('project.add.timeNotification')"
                               v-slot="{ errors }"
                               class="form-group">
            <label>{{ $i18n.t('project.add.timeNotification') }}</label>
            <v-select
                    dir="rtl"
                :options='dateList'
                v-model='selectedTime'
                @search:blur="getTime"
                :class="{'input-error':errors[0]}"
                label="title"

            >
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6">
          <validation-provider ref="typeList" :name="$i18n.t('setting.welcomeComponent.notifyWay')"
                               v-slot="{ errors }"
                               class="form-group">
            <label>{{ $i18n.t('setting.welcomeComponent.notifyWay') }}</label>
            <v-select
                    dir="rtl"
                :options='typeList'
                v-model='selectedType'
                @search:blur="getType"
                :class="{'input-error':errors[0]}"
                label="title"
            >
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 action">
          <button class="blue" >{{ $i18n.t('setting.welcomeComponent.save') }}</button>
        </div>
      </div>
    </valid-form></div>
    <div class="col-lg-7 col-md-6 col-xs-12 col-sm-8 show-welcome">
          <div class="user-info" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" v-show="title">
            <div class="user-name">{{ title  |slice(1,true) }}</div>
            <span class="welcome">{{ title }}</span>
            <span class="describe"> {{ content }}
              </span>
          </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import helpers from "../../../../mixin/helpers";
export default {
  name: 'welcome-component',

  data() {
    return {
      content:'',
      type:'',
      time:'',
      title:'',
      selectedType: null,
      selectedTime:null,
      uploading: {
        isUploading: false,
        selfie_img: {
          state: 0,
          name: null,
          file: null,
        },
      },
      dateList: [
        {
          title: i18n.t('setting.welcomeComponent.nowAdd'),
          value: '0',

        },
        {
          title: i18n.t('setting.welcomeComponent.onceDaysAgo'),
          value: '-1',
        },
        {
          title: i18n.t('setting.welcomeComponent.twiceDaysAgo'),
          value: '-2',
        },
        {
          title: i18n.t('setting.welcomeComponent.threeDaysAgo'),
          value: '-3',
        },
      ],
      typeList: [
        {
          title: i18n.t('setting.welcomeComponent.email'),
          value: 'mail',

        },
        {
          title: i18n.t('setting.welcomeComponent.sms'),
          value: 'sms',
        },
        {
          title: i18n.t('setting.welcomeComponent.both'),
          value: 'mail_sms',
        },

      ],
    }
  },
  created() {
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    },
    bgImage() {
      if(this.uploading['selfie_img'].state===2){
        let asset_id = this.uploading['selfie_img'].file?.id;
        return 'http://eos.yekonline.ir/public/api/assets/' + asset_id + '/render?width=100%&height=235'
      }
      else {
        return  ''
      }

    }
  },
  methods:{
    uploadFile(name) {
      let vm = this;
      if (vm.$refs[name].files.length === 0) {
        return
      }
      let type = vm.$refs[name].files[0].type;
      if (this.$refs[name].files.length > 0 && (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg')) {
        vm.uploading[name].state = 1;
        vm.uploading.isUploading = true;
        this.selectFileToUpload(vm.$refs[name].files, 'document',
            function (response) {
              vm.uploading[name].file = response.data.data;
              vm.uploading[name].state = 2;
              vm.uploading.isUploading = false;
            }, function (error) {
              vm.uploading[name].state = -1;
              vm.uploading[name].name = vm.errors.items[0].msg;
              vm.uploading.isUploading = false;
            });

      } else {
        vm.uploading[name].state = -1;
      }
    },
    getTime() {
      this.time = this.selectedTime.value
    },
    getType() {
      this.type = this.selectedType.value
    },
    add() {
      let vm = this;
      axios.post('emp/settings', {
        'asset_id':vm.uploading['selfie_img'].file?.id,
        'title':vm.title,
        'content':vm.content,
        'notify':vm.time,
        'channel':vm.type
      }).then((response) => {
        vm.$toast.success({title: vm.$i18n.t('setting.welcomeComponent.success')});

      }).catch((err) => {
        vm.handleApiError(err)
      })

    }
  },

  mixins: [helpers],
  components: {CustomInput,validForm
  }
};
</script>
