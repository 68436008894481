<template>
  <div class="auth">
    <div class="auth-block-content pull-right">
      <router-view></router-view>
    </div>
    <div class="pull-left slide-show">
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'auth',
  methods: {},

}
</script>
