<template>
  <div class="panel-container show-project" v-if="showProject">
    <div class="row">

      <loadData v-if="$store.state.application.loadings.task.isActive"></loadData>
      <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 right">
        <div class="row border-bottom">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                <div class="user-name">{{ showProject.name  |slice(1,true) }}</div>
                <p class="user">{{ showProject.name }}</p>
                <p class="date">{{ showProject.created_at|jalaliFormat|persianNumbers }}</p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12">
                <div class="row first">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <custom-input
                        :readonly="true"
                        v-model="departmentInfo.title"
                        :label="$i18n.t('common.department')"
                    />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <custom-input
                        :readonly="true"
                        :ltr="true"
                        v-model="showProject.email"
                        :label="$i18n.t('common.email')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <custom-input
                        :readonly="true"
                        v-model="job.title"
                        :label="$i18n.t('team.position')"
                    />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <custom-input
                        :readonly="true"
                        v-model="showProject.mobile"
                        :ltr="true"

                        :label="$i18n.t('project.add.phoneNumber')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 percent">
                <p v-for="item in showProject.tasks_process.data">
                  <k-progress v-if="item.percent"
                              type="line" :percent="parseInt(item.percent)">
                  </k-progress>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
               :class="{'blur blur-height':$store.state.application.loadings.task.isActive}">
            <p class="title"> {{ $i18n.t('project.show.companyTask') }}</p>
            <div v-if="taskLenght>0">
              <div v-for="check in personalTask" :check="check" :key="check.id">
              <p class="icon heading icon-minimize" v-if="typeTwo[check.id] ">
                {{ check.title }}</p>
                <div class="table-box">
              <table class="table">
                <singleType2 v-for="task in typeTwo[check.id]||[]" :task="task" :key="task.id"></singleType2>
              </table>
                </div>
            </div>
            </div>

            <p class="center-align" v-else>{{ $i18n.t('project.show.empty') }}</p>


            <p v-if="taskCompany.length>0" class="icon heading icon-minimize">{{$i18n.t('project.show.noCheckList')}}</p>
            <div class="table-box">
            <table class="table">
              <singleType2 v-for="task in taskCompany" :task="task" :key="task.id"></singleType2>
            </table></div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 left"
           :class="{'blur blur-height':$store.state.application.loadings.task.isActive}">
        <p class="title"> {{ $i18n.t('project.show.userTask') }}</p>

        <div v-if="taskLenght>0">
        <div v-for="check in companyTask" :check="check" :key="check.id">
          <p class="icon heading icon-minimize" v-if="typeOne[check.id] ">
            {{check.title}}</p>
          <div class="table-box">
          <table class="table">
            <singleTask v-for="task in typeOne[check.id]||[]" :task="task" :key="task.id"></singleTask>
          </table>
          </div>
        </div>
        </div>
        <p class="center-align" v-else>{{ $i18n.t('project.show.empty') }}</p>
        <p v-if="taskPersonal.length>0" class="icon heading icon-minimize">{{ $i18n.t('project.show.noCheckList') }}</p>
        <div class="table-box">
        <table class="table">
          <singleTask v-for="task in taskPersonal" :task="task" :key="task.id"></singleTask>

        </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "../partials/form/customInput.vue";
import loadData from '../partials/form/loadData'
import singleTask from './partials/singleTask'
import singleType2 from './partials/singleType2'

export default {
  name: 'show-project',
  data() {
    return {
      openBox: true
    }
  },
  created() {
    this.getShowProject()
    this.getChecklist()

  },
  computed: {
    typeOne() {
      let type_1 = _.filter(this.$store.state.project.showProject.tasks.data, 'checklist_id')
      let one = _.groupBy(type_1, 'checklist_id')
      return one
    },
    companyTask() {
      let task = _.filter(this.$store.state.task.checkList, ['is_personal', 0])
      return task
    },

    personalTask() {
      let task = _.filter(this.$store.state.task.checkList, ['is_personal', 1])
      return task
    },
    typeTwo() {
      let type_2 = _.filter(this.$store.state.project.showProject.tasks.data, 'checklist_id')
      let two = _.groupBy(type_2, 'checklist_id')
      return two
    },
    taskLenght(){
      return this.$store.state.project.showProject.tasks.data.length
    },

    taskPersonal() {
      let is_personal = _.filter(this.$store.state.project.showProject.tasks.data, ['is_personal', 1])
      let task = _.filter(is_personal, ['checklist_id', null])
      return task
    },
    taskCompany() {
      let is_personal = _.filter(this.$store.state.project.showProject.tasks.data, ['is_personal', 0])
      let task = _.filter(is_personal, ['checklist_id', null])
      return task
    },

    showProject() {
      return this.$store.state.project.showProject;
    },
    job() {
      return this.showProject.job_title.data
    },
    departmentInfo() {
      return this.showProject.department.data
    },

  },
  methods: {
    getChecklist(){
      let vm = this;
      vm.$store.state.application.loadings.task.isActive = true;
      axios.get('emp/check_lists').then((res) => {
        vm.$store.commit('task/set_check_List', res.data.data);
        vm.$store.state.application.loadings.task.isActive = false;

      }).catch(() => {
      });
    },
    getShowProject() {
      let vm = this;
      let uniqId = vm.$route.params.id;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get ('emp/users/' + uniqId).then((response)=> {
        vm.$store.commit('project/set_detail_project', response.data.data);
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch((error)=> {
      });

    },
  },

  components: {CustomInput, loadData, singleTask, singleType2}
}
</script>