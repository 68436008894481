export default {
  namespaced: true,
  state: {
    userList: null,
    jobList: '',
    showProject: '',
    addressList: '',
    departmentList: '',
  },

  mutations: {

    setUserList(state, userList) {
      state.userList = userList;
    },
    set_detail_project(state, showProject) {
      state.showProject = showProject;
    },
    setJobTitleList(state, jobList) {
      state.jobList = jobList;
    },
    setDepartmentList(state, departmentList) {
      state.departmentList = departmentList;
    },
    setAddressList(state, addressList) {
      state.addressList = addressList;
    },

  },
  actions: {
    userList({commit,state}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/users').then(function(response) {
          commit('setUserList', response.data.data);

          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });
    },
    showProject({commit}){
      let uniqId = window.router.app._route.params.id;
        axios.get ('emp/users/' + uniqId).then(function(response) {
          commit('set_detail_project', response.data.data);
        }).catch(function(error) {
        });


    },
    jobTitleList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/job_titles').then(function(response) {
          commit('setJobTitleList', response.data.data);
          resolve();
        }).catch(function(error) {

          reject(error);
        });
      });
    },
    departmentList({commit,state}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/departments').then(function(response) {
          commit('setDepartmentList', response.data.data);
          resolve();
        }).catch(function(error) {

          reject(error);
        });
      });
    },
    addressList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/addresses').then(function(response) {
          commit('setAddressList', response.data.data);
          resolve();
        }).catch(function(error) {

          reject(error);
        });
      });
    },


  },
};
