<template>
  <div class="panel-container project-page">
    <div class="row padding-box">
      <div class="col-lg-12 col-md-12 col-xs-12  col-sm-12">
        <div class="tabs-details" :class="{'blur':$store.state.application.loadings.task.isActive}">
          <div class="head">
            <div>
              <div :list="tabs" v-bind="dragOptions" >
                <div class="tabs" name="list-complete">
                  <template v-for="tab in tabs">
                    <div class="tab-item"
                        :class="[active(tab.id), 'tab',{'-active': parseInt(current)===tab.id}] "
                        :key="tab.id"
                        @mousedown="changeTab(tab.id)">
                      <p class="text">{{ tab.title }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="first">
<!--                <button class="secondary-btn ">{{ $i18n.t('project.atsLogin') }}</button>-->
                <button class="blue " @click="addProject = true">
                  <span class="icon icon-add"></span>
                  {{ $i18n.t('project.addUser') }}
                </button>
              </div>
            </div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 search">
                  <custom-input
                      v-model="queryVariables.filter_value"
                      inputName="queryVariables.filter_value"
                      @input="Search"
                      :placeholder="$i18n.t('common.search')"
                  />
                  <span class="icon icon-search"></span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 left-align" v-if="false">

                </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="table-box">
                <table :class="{'blur blur-height':$store.state.application.loadings.project.isActive}" class="table"  >
                  <thead >
                  <tr class="title-row">
                    <th style="padding-right: 10px">
                      <span class="icon icon-arrow-up up" @click="orderAsc('name')"></span>
                      <span class="icon icon-arrow-down down" @click="orderDesc('name')"></span>
                      {{ $i18n.t('project.name') }}
                    </th>

                    <th>
                      <span class="icon icon-arrow-up up" @click="orderAsc('department_id')"></span>
                      <span class="icon icon icon-arrow-down down" @click="orderDesc('department_id')"></span>
                      {{ $i18n.t('common.department') }}


                    </th>
                    <th>
                      <span class="icon icon-arrow-up up" @click="orderAsc('work_started_at')"></span>
                      <span class="icon icon icon-arrow-down down" @click="orderDesc('work_started_at')"></span>
                      {{ $i18n.t('project.startTime') }}
                    </th>
                    <th>
                      <span class="icon icon-arrow-up up" @click="orderAsc('owner_user_id')"></span>
                      <span class="icon icon-arrow-down down" @click="orderDesc('owner_user_id')"></span>
                      {{ $i18n.t('project.worker') }}
                    </th>
                    <th>
                      <span class="icon icon-arrow-up up" @click="orderAsc('work_progress')"></span>
                      <span class="icon icon-arrow-down down" @click="orderDesc('work_progress')"></span>
                      {{ $i18n.t('project.percentWork') }}

                    </th>

                  </tr>
                  </thead>
                  <tbody >
                  <projectList  ref="list" v-for="user in userData" :key="user.id" :user="user"></projectList>
                  <tr class="empty-data"  v-if="userData.length===0">
                    <td   colspan="5">{{ $i18n.t('common.empty') }}
                    </td>
                  </tr>
                  </tbody>
                  <popover name="editProfile">
                    <div class="info-profile">
                      <p class="edit" @click="editProject=true">
                        {{ $i18n.t('common.edit') }}
                      </p>
                      <p class="remove" @click="question=true">{{ $i18n.t('common.remove') }}</p>
                    </div>
                  </popover>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <question v-if="question" @confirmed="remove()" ref="RemoveQuestion"
              :content=" $i18n.t('common.removeText')"></question>
    <edit-project v-if="editProject"></edit-project>
    <add-project v-if="addProject"></add-project>
  </div>
</template>

<script>
import projectList from './partials/projectList';
import addProject from './partials/addProject';
import editProject from './partials/editProject';
import question from '../partials/form/question';
import loadData from "../partials/form/loadData";
import CustomInput from "../partials/form/customInput";


export default {
  data() {
    return {
      currentTabId: 1,
      type_id: '',
      editProject:false,
      addProject: false,
      question: false,
      current: null,
      userId: '',
      queryVariables: {
        type: this.$route.params.type,
        type_id: this.type_id,
        filter_value:''
      },
      queryStrings: this.$route.query,

      nextTabId: 4,

    };
  },
  computed: {

    active() {
      return (id) =>id === this.$data.currentTabId && "-active";
    },
    tabs() {
      return this.$store.state.task.typeList;
    },
    dragOptions() {
      return {
        animation: 250,
        disabled: false,
        ghostClass: "-dragging"
      };
    },
    userData(){
        return this.$store.state.project.userList || {};
    }
  },
  created() {
    this.queryVariables = this.queryStrings;
    this.current=this.$data.currentTabId = this.type_id=this.queryStrings.type_id||'1';
    this.getType();
    this.fetchUser();
  },
  methods: {
    getType() {
      let vm = this;
      vm.$store.state.application.loadings.task.isActive = true;
      axios.get('emp/types').then(function(response) {
        vm.$store.commit('task/set_type_List', response.data.data);
        vm.$store.state.application.loadings.task.isActive = false;
      }).catch(()=> {});
    },
    fetchUser() {
      let vm = this;
      let queryStringPage = {...vm.$route.query};
      vm.queryVariables = queryStringPage;
      vm.queryVariables.type_id = queryStringPage.type_id=vm.type_id;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users', {
        params: vm.queryVariables,
      }).then((response) => {
        vm.$store.commit('project/setUserList', response.data.data);
        vm.$router.replace({
          query:vm.queryVariables
        }).catch(() => {});
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch(() => {});
    },
    changeTab(id) {
      let vm=this;
      vm.$data.currentTabId = id;
      vm.current = id;
      let queryStringPage = {...vm.$route.query};
      vm.queryVariables = queryStringPage;
      vm.queryVariables.type_id = id;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users', {
        params: this.queryVariables,
      }).then((response) => {
        vm.$store.commit('project/setUserList', response.data.data);
        vm.$router.replace({
          query:vm.queryVariables
        }).catch(err => {});
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch(() => {
      });
    },
    orderDesc(value) {
      let vm = this;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users', {
        params: {
          'order_by': value,
          'order_type': 'asc',
          'type_id': vm.queryVariables.type_id,

        },
      }).then((response) => {
        this.$router.replace({
          query: {
            'order_by': value,
            'order_type': 'asc',
            'type_id': vm.queryVariables.type_id,

          },
        }).catch(() => {});
        vm.$store.commit('project/setUserList', response.data.data);
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch(() => {});

    },
    orderAsc(value) {
      let vm = this;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users', {
        params: {
          'order_by': value,
          'order_type': 'desc',
          'type_id': vm.queryVariables.type_id,
        },
      }).then((response) => {
        this.$router.replace({
          query: {
            'order_by': value,
            'order_type': 'desc',
            'type_id': vm.queryVariables.type_id,

          },
        }).catch(() => {});
        vm.$store.commit('project/setUserList', response.data.data);
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch((error) => {});

    },
    remove() {
      let vm = this;
      let uniqId = vm.userId;
      axios.delete('emp/users/' + uniqId).then((res) => {
        vm.fetchUser();
        vm.$store.commit('changeModalStatus', false);
      }).catch((err) => {
        vm.handleApiError(err);
      });
    },
    Search() {
      let vm = this;
      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users', {
        params: {
          'filter_by': 'all',
          'filter_value': vm.queryVariables.filter_value,
        },
      }).then((response) => {
        vm.$store.commit('project/setUserList', response.data.data);
        vm.$router.replace({
          query:vm.queryVariables
        }).catch(() => {});
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch((error) => {
      });
    },

  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.addProject = val;
        this.editProject = val;
        this.question = val;
      }
    },
  },

  components:{
    addProject, projectList, loadData, question, editProject,CustomInput
  }
};
</script>

