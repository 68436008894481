export default {
  namespaced: true,
  state: {
    roleList: '',
    permission: '',
    actionRole:[]
  },

  mutations: {
    set_role_list(state, roleList) {
      state.roleList = roleList;
    },
    set_permission_list(state, permission) {
      state.permission = permission;
    },
    set_action_role(state, actionRole) {
      state.actionRole = actionRole;
    },
  },
  actions: {
    getRoleList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/company/roles').then(function(response) {
          commit('set_role_list', response.data.data);
          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });
    },
    permission({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/company/permission').then(function(response) {
          commit('set_permission_list', response.data.data);
          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });
    },
  },
};
