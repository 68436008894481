import Vue from 'vue';

Vue.mixin({
  methods: {
    selectFileToUpload(file,type, callback, errCallback, elName = null ,vm = null) {
      let files = file;
      if (!files.length)
        return;
      this.upload(files[0],type, callback, errCallback, elName)
    },
    upload(file,type, callback, errCallback, elName,vm) {
      if(!vm){
        vm = this;
      }
      let formData = new FormData();
      formData.append('file', file);

      axios.post(
        '/assets',
        formData
      ).then((response) => {
        callback(response);

      }).catch((error) => {
          errCallback(error);
        });
    },
  },
});
