<template>
  <modal class="add-user" :classes="'add-team'">
    <div class="header" slot="title">
      <p>{{ $i18n.t('setting.add.create') }}</p>
    </div>
    <valid-form :request="add" slot="body" ref="form">
      <div class="row" v-if="$route.hash === '#company_actions' ||$route.hash === ''">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <custom-input
              v-model="title"
              :label="$i18n.t('setting.add.title')"
              inputName="title"
              rules="required"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <validation-provider ref="department" :name="$i18n.t('common.department')" rules='required'
                               v-slot="{ errors }"
                               class="form-group">
            <label>{{ $i18n.t('common.department') }}</label>
            <v-select
                    dir="rtl"
                :options='departmentList'
                v-model='selectedDepartment'
                @search:blur="getId"
                :class="{'input-error':errors[0]}"
                label="title">
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <validation-provider ref="dateList" :name="$i18n.t('setting.add.notification')" rules='required'
                               v-slot="{ errors }"
                               class="form-group">
            <label>{{ $i18n.t('setting.add.notification') }}</label>
            <v-select
                    dir="rtl"
                :options='dateList'
                v-model='selectedTime'
                @search:blur="getNotify"
                :class="{'input-error':errors[0]}"
                label="title"
                rules="required"
            >
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <validation-provider ref="ownerList" :name="$i18n.t('setting.add.teamOwner')" rules='required'
                               v-slot="{ errors }"
                               class="form-group">
            <label>{{ $i18n.t('setting.add.teamOwner') }}</label>
            <v-select
                    dir="rtl"
                :options='ownerList'
                v-model='selectedOwner'
                @search:blur="getOwner"
                :class="{'input-error':errors[0]}"
                label="title"
                rules="required"
            >
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

          </validation-provider>
        </div>
        <div class="col-lg-12 col-md-12 btn">
          <button class="blue">{{ $i18n.t('setting.add.submit') }}</button>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <custom-input
              v-model="title"
              :label="$i18n.t('setting.add.title')"
              inputName="title"
              rules="required"
          />
        </div>
        <div class="col-lg-12 col-md-12 btn">
          <button class="blue">{{ $i18n.t('setting.add.submit') }}</button>
        </div>
      </div>
    </valid-form>
  </modal>
</template>
<script>

import modal from '../../partials/modal.vue';
import helpers from "../../../../mixin/helpers";
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";

export default {
  name: 'add_action',
  data() {
    return {
      title: '',
      have_notify: '',
      is_personal:'',
      type_id:'',
      owner_id: '',
      department_id: '',
      selectedOwner: null,
      selectedDepartment: null,
      selectedTime: null,
      dateList: [
        {
          title: i18n.t('setting.add.have'),
          value: '1',

        },
        {
          title: i18n.t('setting.add.doNotHave'),
          value: '0',

        },
      ],
    };
  },
  created() {
    this.$store.dispatch('project/departmentList');
    this.$store.dispatch('team/teamList')
    this.$store.dispatch('task/typeList')

  },

  computed: {
    departmentList() {
      let department = _.filter(this.$store.state.project.departmentList, (item) => {
        return item
      });
      return _.map(department, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    ownerList() {
      let owner = _.filter(this.$store.state.team.teamMember, (item) => {
        return item
      });
      return _.map(owner, function (item) {
        if (item.title !== null) {
          return {
            title: item.name,
            id: item.id,
          };
        } else {
          return {
            title: item.name,
            id: item.id,
          }
        }
      });
    },
  },


  methods: {

    getId() {
      this.department_id = this.selectedDepartment.id
    },
    getNotify() {
      this.have_notify = this.selectedTime.value
    },
    getOwner() {
      this.owner_id = this.selectedOwner.id
    },



    add() {
      let vm = this;
      if(this.$route.hash === '#company_actions' ||this.$route.hash === ''){
        vm.type_id='2'
        vm.is_personal='0'
      }
      else if(this.$route.hash === '#employee_actions'){

        vm.type_id='1'
        vm.is_personal='1'
      }

      axios.post('/emp/check_lists', {
        'title': vm.title,
        'department_id': vm.department_id,
        'owner_id': vm.owner_id,
        'have_notify': vm.have_notify,
        'type_id': vm.type_id,
        'is_personal': vm.is_personal,
      }).then((response) => {
        this.$store.dispatch('task/checkList');

        vm.$store.commit('changeModalStatus', false);

      }).catch((err) => {
        vm.handleApiError(err)
      })

    }

  },
  mixins: [helpers],

  components: {
    modal, CustomInput, validForm
  }
};
</script>
