import Vue from 'vue';
// import vueTabz from 'vue-tabz';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import lodash from 'lodash';
import moment from 'moment-jalaali';
import ToggleButton from 'vue-js-toggle-button';
import Popover from 'vue-js-popover';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import KProgress from '../components/pages/partials/kprogress';
import VPopover from 'vue-js-popover'

Vue.use(VPopover, {tooltip: true})
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.component('k-progress', KProgress);
Vue.use(ToggleButton);
Vue.use(Popover)

import './router';
import './tab';
import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import '../validation/extraRules';
import fa from '../lang/validation/faMessages.json';
import CxltToastr from './cxlt-vue2-toastr';
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip';
import '../mixin/upload';

Vue.component('v-select', vSelect)
Vue.use(VTooltip, {
    defaultPlacement: 'right',
});


window.Vue = Vue;

Vue.use(CxltToastr, {
    closeButton: true,
    position: 'top center',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 1000,
    hideDuration: 1000,
    delay: 0,
    timeOut: '2400'
});

window._ = lodash;
window.axios = axios;
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
for (let [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation
    });
}
// Vue.use(vueTabz);

localize('fa', fa);
window.fa = fa;
window.moment = moment;
moment.loadPersian();
require('../filters/index');
require('./i18n');
require('../store/index');
axios.defaults.baseURL = '/public/api/';
