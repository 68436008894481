<template>
  <div class="col-lg-4 col-md-4  col-sm-6 col-xs-12">
    <div class="permission-component ">
      <p class="title"> {{ permission.title }}{{ permission.id }}
      </p>
      <div class="action">
        <p v-for="action in permission.action" :key="action.id">
          <input  type="checkbox" name="title" value="action.id" :checked="actionExist(action.id)" @input="update(action.id)">
          <label> {{ action.title |strLimit(30) }}</label>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'show-permission',
  props: ['permission'],

  methods: {
    actionExist(id) {
      return this.$store.state.company.actionRole.indexOf(id) !== -1;
    },
    update(id){
      let vm = this;
      let uniqId = vm.$route.params.id;
      let roleId = _.filter(this.$store.state.company.roleList, ['id',uniqId])
      axios.put('emp/company/roles', {
        "role_id": id,
        "title":roleId[0].title,
        "action_id": [id],
      }).then(() => {
      }).catch((err) => {
        vm.handleApiError(err)
      });
    }
  },
};
</script>
