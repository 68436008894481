<template>
  <tbody class="single-Task" role="row">
  <tr :class="{'simple-row':!openBox}">
    <td class="title-task">{{ task.title  |strLimit(10) }}</td>
    <td class="status" :class="{'pending':task.status===0,'done':task.status===2,'in-process':task.status===1}">
    {{ $i18n.t('project.show.status.'+task.status) }}

    </td>
    <td class="left-align ">{{ task.created_at|jalali|persianNumbers }}</td>
    <td class="icon-box">
      <a v-if="task.link" :href="task.link" target="_blank">
        <span class="icon icon-attach-link"></span>

      </a>
      <span class="icon icon-copy"></span>
      <span :class="{'icon-up':openBox,'icon-down':!openBox}" class="pointer icon "  @click="openBox = !openBox"></span>
    </td>
  </tr>
  <tr v-if="openBox" class="open">
    <td colspan="4">{{ task.description |strLimit(170)}}</td>
  </tr>

  </tbody>
</template>

<script>
export default {
  name: 'single-task',
  props: ['task'],
  data() {
    return {
      openBox: false
    }
  }

}
</script>
