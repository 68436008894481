<template>
  <div class="panel-container my-check-list-page">
    <div class="row ">
      <div class="col-lg-6 col-md-6 col-xs-12  col-sm-offset-1 col-sm-10 right-row padding-box">
        <div class="row">
          <div class="col-xs">
            <div class="user-info" :style="tabsWidth" v-if="user.name">
              <div class="user-name">{{ user.name  |slice(1,true) }}</div>
              <span class="welcome">{{ welcomeMassage.title }}</span>
              <span class="text"> {{ welcomeMassage.content }}
              </span>
              <div class="full-name">
                <!--                <img class="avatar" v-if="ownerInfo.avatar" :src="ownerInfo.avatar">-->
                <div class="avatar-load"></div>

                <span class="medium-font full-name " v-if="ownerInfo.name">{{ ownerInfo.name | strLimit(30) }}</span>
                <span v-else>--</span>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="project-component ">
              <singleProject ref="tasks" v-for="task in user.tasks.data" :key="task.id" :task="task"></singleProject>
              <popover name="foo">
                <div class="status-box">
                  <p class="in-process" @click="toggle(status,1)">
                    <span class="icon-status"></span>{{ $i18n.t('myCheckList.status.inProcess') }}</p>
                  <p class="done" @click="toggle(status,2)">
                    <span class="icon-status">{{ $i18n.t('myCheckList.status.done') }}</span></p>
                  <p class="pending" @click="toggle(status,0)">
                    <span class="icon-status">{{ $i18n.t('myCheckList.status.pending') }}</span></p>
                </div>
              </popover>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 col-sm-offset-1 col-sm-10 left-row padding-box">
        <div class="row">
          <div class=" col-lg-5 col-md-5 col-xs-12  col-sm-5 right-data ">
            <!--            <img class="avatar" v-if="ownerInfo.avatar" :src="ownerInfo.avatar">-->
            <img class="avatar"
                 src="/images/icons/user.svg">
            <span class="medium-font user-name " v-if=" ownerInfo.name">{{ ownerInfo.name }}</span>
            <span v-else> -- </span>
            <span> {{ $i18n.t('myCheckList.withYou') }}</span>
          </div>
          <div class="col-lg-7  col-md-7 col-xs-12  col-sm-7 left-data">
            <span class="fa-number">{{ ownerInfo.mobile }}</span>
            <span class="fa-number">{{ ownerInfo.email }}</span>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12  col-sm-12">
            <div class="divider"></div>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12  col-sm-12">
            <singleItem v-for="detailProject in detailProjects" :key="detailProject.id"
                        :detailProject="detailProject"></singleItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import singleItem from './partials/singleItem'
import singleProject from './partials/singleProject'
import helpers from "../../../mixin/helpers";

export default {
  name: 'myCheckList',
  data() {
    return {
      status: '',
    }
  },
  computed: {

    welcomeMassage() {
      return this.user.welcome_massage.data
    },
    user() {
      return this.$store.state.auth.user ||{}
    },
    ownerInfo() {
      return this.user.owner.data
    },
    detailProjects() {
      let vm = this;
      let uniqId = vm.$route.params.id;
      let dataTask = _.filter(this.user.tasks.data, {'id': uniqId});
      return {...dataTask}
    },
    tabsWidth() {
      let asset_id = this.$store.state.auth.user.welcome_massage;
      let url='http://eos.yekonline.ir/public/api/assets/' + asset_id.data.asset_id + '/render?width=100%&height=235'

      return {
        backgroundImage:'url(' + url + ')',
      }
    },
    bgImage() {
      let asset_id = this.$store.state.auth.user.welcome_massage.data.asset_id;
      return 'http://eos.yekonline.ir/public/api/assets/' + asset_id + '/render?width=100%&height=235'
    }
  },
  created() {
    axios.get ('me').then((response)=>{
      this.$store.commit('auth/setUser', response.data.data)
    }).catch(()=>{})
  },
  methods: {
    toggle(status, value) {
      let vm = this;
      let uniqId = vm.$route.params.id;
      vm[status] = value;
      axios.post('tasks/' + uniqId, {
        'status': vm[status],
      }).then((res) => {
        let select=_.filter(this.user.tasks.data, {'id': uniqId})
        select[0].status= res.data.data.status
        vm.$toast.success({title: vm.$i18n.t('myCheckList.updateSuccess')});

      }).catch((err) => {
        vm.handleApiError(err)
      });
    },
  },
  mixins: [helpers],

  components: {
    singleProject, singleItem
  }

}
</script>
