<template>
  <modal class="add-user"  :classes="'add-team'">
    <div class="header" slot="title">
      <p>{{$i18n.t('team.add.invite')}}</p>
    </div>
      <valid-form :request="add" slot="body" ref="form" >
        <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <custom-input
                :ltr="true"
                v-model="email"
                :label="$i18n.t('common.email')"
                inputName="email"
                rules="required|email"
            />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <validation-provider  :name="$i18n.t('team.position')" rules='required'
                                v-slot="{ errors }" class="form-group">
          <label>{{ $i18n.t('team.position') }}</label>
          <v-select
                  dir="rtl"
              ref="jobInfo"
              :class="{'input-error':errors[0]}"
              :options=$parent.jobInfoList
              v-model=$parent.selectedJob.id
              label="title">
          </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <validation-provider  :name="$i18n.t('team.role')" rules='required'
                               v-slot="{ errors }" class="form-group">
          <label>{{ $i18n.t('team.role') }}</label>
          <v-select
                  dir="rtl"
              ref="roleInfo"
              :class="{'input-error':errors[0]}"
              :options=$parent.roleInfoList
              v-model=$parent.selectedRole.id
              label="title">
          </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn">
          <button class="blue" >{{ $i18n.t('team.add.inviteSubmit') }}</button>
        </div>
        </div>
      </valid-form>
  </modal>
</template>
<script>

import modal from '../../partials/modal.vue';
import helpers from "../../../../mixin/helpers";
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";

export default {
  name: 'addTeam',
  data() {
    return {
      email:''

    };
  },
  created() {

  },

  computed: {


  },

  mounted() {
  },
  methods: {
    close(){
      this.$store.commit('changeModalStatus', false);
    },


  add() {
    let vm = this;
    let role_id=vm.$parent.selectedRole.id.id;
    let job_id=vm.$parent.selectedJob.id.id;
    axios.post('emp/teams', {
      'email':vm.email,
      'role_id':role_id,
      'job_title_id':job_id
    }).then((response) => {
      vm.$toast.success({title: vm.$i18n.t('team.add.success')});
      vm.$store.commit('changeModalStatus', false);

    }).catch((err) => {
      vm.handleApiError(err)
    })

  }

  },
  mixins: [helpers],

  components: {
    modal,CustomInput,validForm
  }
};
</script>