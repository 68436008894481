<template>
  <div class="row first-step">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <validation-provider ref="department" :name="$i18n.t('common.department')" rules='required' v-slot="{ errors }"
                           class="form-group">
        <label>{{ $i18n.t('common.department') }}</label>
        <v-select
             dir="rtl"
            :options='departmentInfo'
            v-model='parentData.selected'
            @search:blur="parentData.getDepartmentId"

            :class="{'input-error':errors[0]}"
            label="title">
        </v-select>
        <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

      </validation-provider>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <validation-provider ref="teamList" :name="$i18n.t('project.add.name2')" rules='required' v-slot="{ errors }"
                           class="form-group">
        <label>{{ $i18n.t('project.add.name2') }}</label>
        <v-select
                dir="rtl"
            :options='teamMember'
            v-model=parentData.selectedTeam
            @search:blur="parentData.getUserId"

            :class="{'input-error':errors[0]}"
            label="title">
        </v-select>
        <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

      </validation-provider>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <custom-input
          :ltr="true"
          @input="parentData.convert"
          v-model="parentData.mobile"
          :label="$i18n.t('project.add.phoneNumber')"
          inputName="mobile"
          rules="required"
      />
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <custom-input
          :ltr="true"
          v-model="parentData.email"
          :label="$i18n.t('common.email')"
          inputName="email"
          rules="required|email"
      />
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <validation-provider ref="time" :name="$i18n.t('project.add.time')" rules='required' v-slot="{ errors }"
                           class="form-group">
        <label>{{ $i18n.t('project.add.time') }}</label>

        <date-picker v-model="parentData.time" :max="today"/>
        <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

      </validation-provider>

    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <validation-provider ref="hour" :name="$i18n.t('project.add.hours')" rules='required' v-slot="{ errors }"
                           class="form-group">

        <label>{{ $i18n.t('project.add.hours') }}</label>
        <date-picker v-model="parentData.hours" rules="required" type="time"/>
        <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

      </validation-provider>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 action-btn">
      <button class="blue">{{ $i18n.t('common.next') }}</button>
    </div>
  </div>
</template>
<script>

import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import helpers from "../../../../mixin/helpers";

export default {
  name: 'first-step',
  data() {
    return {
      today: moment().format('jYYYY/jMM/jDD'),

    };
  },
  computed: {
    teamMember() {
      let team = _.filter(this.$store.state.team.teamMember, (item) => {
        return item
      });
      return _.map(team, function (item) {
        if (item.title !== null) {
          return {
            title: item.name,
            id: item.id,
          };
        } else {
          return {
            title: item.name,
            id: item.id,
          }
        }
      });
    },
    departmentInfo() {
      let department = _.filter(this.$store.state.project.departmentList, (item) => {
        return item
      });
      return _.map(department, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },

    parentData() {
      return this.$parent.$parent.$parent.$parent
    },
  },
  methods: {

  },
  mixins: [helpers],

  components: {
    validForm, CustomInput
  }
};
</script>