<template>
  <modal class="add-user" :classes="'add-modal'">
    <div slot="title"></div>
    <valid-form slot="body" ref="form" :request="editUser">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div class="upload-img">
            <img src="images/avatarAdd.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <custom-input
              v-model='name'
              :label="$i18n.t('common.fullName')"
              inputName="name"
              rules="required"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <validation-provider ref="positionSelect" :name="$i18n.t('team.position')" rules='required'
                               v-slot="{ errors }" class="form-group">
            <label>{{ $i18n.t('team.position') }}</label>
            <v-select
                    dir="rtl"
                :options='jobInfoList'
                v-model='selectedJob'
                @search:blur="getJobId"
                :class="{'input-error':errors[0]}"
                label="title">
            </v-select>
            <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="divider"></div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <ul class="menu-item">
            <li :class="{'is-active':step==='first'}">{{ $i18n.t('project.add.userInfo') }}</li>
            <li :class="{'is-active':step==='second'}">{{ $i18n.t('project.add.employeeProject') }}</li>
            <li :class="{'is-active':step==='third'}">{{ $i18n.t('project.add.userProject') }}</li>

          </ul>
        </div>
      </div>
      <first-step ref="first" v-if="step==='first'" v-model="firstStepValues"></first-step>
      <second-step ref="second" v-if="step==='second'"  v-model="secondStepValues"></second-step>
      <third-step v-if="step==='third'" v-model="thirdStepValues"></third-step>
    </valid-form>


  </modal>
</template>
<script>

import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import modal from '../../partials/modal.vue';
import helpers from "../../../../mixin/helpers";
import FirstStep from "../editProject/firstStep";
import SecondStep from "../editProject/secondStep";
import ThirdStep from "../editProject/thirdSStep";
export default {
  name: 'edit-project',
  data() {
    return {
      name: '',
      time: '',id:'',
      hours: '',
      mobile: '',
      email: '',
      selectedTeam: null,
      selectedJob: null,
      selectedAddress: null,
      selected: null,
      resourceTsk:[],
      job_id: '', department_id: '', address_id: '',
      user_id: '',
      firstStepValues: {
      },
      secondStepValues: {},
      thirdStepValues: {},

      step: 'first',
      steps: [
        {
          key: 'first',
        },
        {
          key: 'second',
        },
        {
          key: 'third',
        },
      ],

    };
  },
  created() {
    this.fillData();
    this.fetchData();

  },

  computed: {
    departmentInfo() {
      let department = _.filter(this.$store.state.project.departmentList, (item) => {
        return item
      });
      return _.map(department, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    jobInfoList() {
      let jobTitle = _.filter(this.$store.state.project.jobList, (item) => {
        return item
      });
      return _.map(jobTitle, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    firstData() {
      return this.$refs.first
    },
    entryAt() {
      let entry_at = this.time + '' + this.hours;
      return moment(entry_at,  'jYYYY/jMM/jDD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm:ss');
    },


  },

  methods: {
    convert() {
      this.mobile = this.englishNumber(this.mobile);
    },
    fetchData() {
      let vm = this;
      this.$store.dispatch('project/jobTitleList')
      vm.$store.dispatch('team/teamList')
      vm.$store.dispatch('project/addressList')
      vm.$store.dispatch('project/departmentList')
    },
    editUser() {
      let vm = this;
      let uniqId = vm.$parent.userId;
      axios.put('emp/users/' + uniqId, {
        'mobile': vm.mobile,
        'name': vm.name,
        'address': vm.address,
        'department_id': vm.department_id,
        'work_started_at': vm.entryAt,
        'email': vm.email,
        'address_id': vm.address_id,
        'owner_user_id': vm.user_id,
        'job_id': vm.job_id,

      }).then((res) => {
        vm.$store.state.auth.user = res;
        vm.step = 'second';
        let data=res.data.data
        vm.id=data.id
      }).catch((err) => {
        vm.handleApiError(err)

      });
    },
    getJobId() {
      this.job_id = this.selectedJob.id
    },
    getDepartmentId() {
      this.department_id = this.selected.id
    },
    getUserId() {
      this.user_id = this.selectedTeam.id
    },
    getAddressId() {
      this.address_id = this.selectedAddress.id
    },
    fillData(){
        let vm = this;
        let uniqId = vm.$parent.userId;
        vm.$store.state.application.loadings.project.isActive = true;
        axios.get('emp/users/' + uniqId).then((res) => {
          vm.$store.state.application.loadings.project.isActive = false;
          let fillData = res.data.data;
          vm.$store.state.task.list=fillData
          vm.resourceTsk=fillData
          vm.name = fillData.name;
          vm.selected = fillData.department.data.title;
          vm.selectedJob = fillData.job_title.data.title;
          vm.selectedTeam = fillData.owner.data.name;
          vm.email = fillData.email;
          vm.mobile = fillData.mobile;
          vm.selectedAddress = fillData.address.data.title;
          vm.time =  moment(fillData.work_started_at,  'YYYY-MM-DD HH:mm:ss ')
          .format('jYYYY/jMM/jDD HH:mm:ss');
          vm.hours = moment(fillData.work_started_at).format('HH:mm:ss')
          vm.job_id = fillData.job_id
          vm.department_id = fillData.department_id
          vm.user_id = fillData.owner.data.id
          vm.address_id=fillData.address.data.id
        }).catch(() => {
        });
    }
  },
  mixins: [helpers],
  components: {
    ThirdStep,
    SecondStep,
    FirstStep,
    modal, validForm, CustomInput
  }
};
</script>
