<template>
  <tr class="single-team" ref="member" role="row">
    <td class="full-name">
      <!--      <img class="avatar" v-if="team.avatar"  :src="team.avatar">-->
      <p class="avatar-load"></p>
      <span v-if="team.name">{{ team.name }}</span><span v-else> -- </span></td>
    <td class="job-title" v-if="team.role">
      <v-select
              dir="rtl"
          @search:blur="updateRole()"
          ref="roleInfo"
          :options='roleInfoList'
          v-model=selectedRole
          label="title">
      </v-select>
    </td>
    <td v-else> --</td>
    <td v-if="team.job_title">
      <v-select
              dir="rtl"
          ref="jobInfo"
          :options='jobInfoList'
          v-model=selectedJob
          @search:blur="updateJob()"
          label="title">
      </v-select>
    </td>
    <td v-else> --</td>
    <td class="status">
      <p v-if="status" class="status-text">{{ $i18n.t('team.active') }} </p>
      <p v-else class="status-text"> {{ $i18n.t('team.inActive') }}</p>
      <toggle-button :class="{'active':status,'inActive':!status}" :disabled="true" v-model="status"
                     color="#2b4a83"></toggle-button>

    </td>
    <td class="delete" @click="remove= true">
      <span class="icon icon-delete"></span>
    </td>
    <remove v-if="remove"></remove>

  </tr>

</template>
<script>
import remove from "./remove";
import helpers from "../../../../mixin/helpers";

export default {
  name: 'member-component',
  props: ['team'],
  data() {
    return {
      remove: false,
      role_id: '',
      job_title_id: '',
      selectedRole: {
        id: this.team.role,
        title: this.team.role
      },
      selectedJob: {
        id: this.team.job_title_id,
        title: this.team.job_title
      }
    }
  },
  computed: {
    roleInfoList() {
      let roleTitle = _.filter(this.$store.state.company.roleList, (item) => {
        return item
      });
      return _.map(roleTitle, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    jobInfoList() {
      let jobTitle = _.filter(this.$store.state.project.jobList, (item) => {
        return item
      });
      return _.map(jobTitle, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    status() {
      if (this.team.is_active === 1) {
        return true
      } else if (this.team.is_active === 0) {
        return false

      }
    },
  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.remove = val;
      }
    }
  },
  methods: {
    updateJob() {
      let vm = this;
      axios.put('emp/teams', {
        "job_title_id": vm.selectedJob.id.toString(),
        "user_id": vm.team.id.toString(),
        "role_id": vm.team.role_id.toString(),

      }).then((res) => {
        vm.$toast.success({title: vm.$i18n.t('team.success')});
      }).catch((err) => {
        // vm.handleApiError(err)
      });
    },


    updateRole() {
      let vm = this;
      axios.put('emp/teams', {
        "user_id": vm.team.id.toString(),
        "job_title_id": vm.team.job_title_id.toString(),
        "role_id": vm.selectedRole.id.toString(),
      }).then((res) => {
        vm.$toast.success({title: vm.$i18n.t('team.success')});
      }).catch((err) => {
        // vm.handleApiError(err)
      });
    },
  },
  mixins: [helpers],

  components: {
    remove
  }
}
</script>
