<template>
  <tr class="single-Project" role="row">

    <td>
      <img class="avatar" v-if="user.avatar" :src="user.avatar">
      <img class="avatar first-col" v-else
           src="images/user.svg">
      <router-link class="info pointer" tag="div" :to="{name:'project.show', params:{id:user.id}}">
        <span class="full-name medium-font">{{ user.name }}</span>
        <span class="job-title">{{ jobTitleInfo.title }}</span>
      </router-link>
    </td>
    <td class="department">{{ departmentInfo.title }}</td>
    <td class="start-time">{{ user.work_started_at|jalali|persianNumbers }}</td>
    <td class="owner"   ><img class="avatar"
                              src="images/user.svg">{{ ownerInfo.name }}</td>
    <td >
      <div class="percent">
        <p  v-for="item in user.tasks_process.data">
          <k-progress v-if="item.percent"
                      type="line" :percent="parseInt(item.percent)">
          </k-progress>
        </p>
      </div>
      <span   @click="getId(user.id)"  class="icon icon-more pointer" v-popover.bottom="{ name: 'editProfile' }"></span>

    </td>

  </tr>
</template>

<script>

export default {
  name: 'project-list',
  props: ['user'],
  computed: {
    ownerInfo() {
      return this.user.owner.data
    },
    departmentInfo() {
      return this.user.department.data
    },
    jobTitleInfo() {
      return this.user.job_title.data
    },


  },
  methods:{
    getId(id){
      this.$parent.userId=id;
    },
  },
  components: {}
}
</script>
