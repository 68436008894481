<template>
  <div class="row request-page">
    <div class="col-lg-8 col-lg-offset-3 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 auto-margin">
      <p class="title">{{ $i18n.t('auth.welCome') }}</p>
      <p class="sub-title">{{ $i18n.t('auth.importCode') }}</p>
      <valid-form :request="getOtp">
        <custom-input v-if="email"
            :ltr="true"
            v-model="email"
            :label="$i18n.t('common.email')"
            inputName="email"
            :readonly="true"
            rules="email"
        />
        <custom-input
            :ltr="true"
            :readonly="true"
            @input="convert"
            v-model="mobile"
            :label="$i18n.t('auth.mobile')"
            inputName="mobile"
            rules="required"
        />
        <custom-input
            :ltr="true"
            @input="convert"
            v-model="otp"
            :label="$i18n.t('auth.otp')"
            inputName="otp"
            rules="required|max:6|min:6"
        />
        <div class="button-submit">

          <button class="auth-btn">{{ $i18n.t('auth.loginWithOtp') }}</button>
        </div>
      </valid-form>
    </div>
  </div>
</template>
<script>
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import helpers from '../../../../mixin/helpers';

export default {
  name: 'auth_loginRequest',
  data() {
    return {
      mobile: '',
      email: '',
      otp: '',

    };
  },
  created() {
    this.mobile = this.$route.params.mobile;
    this.email = this.$route.params.email;

  },
  mounted() {

  },
  methods: {
    convert() {
      this.mobile = this.englishNumber(this.mobile);
      if (this.otp.length === 6) {
        this.getOtp()
      }
    },
    getOtp() {
      let vm = this;
      axios.post('oauth/token', {
          'grant_type': 'password',
          'client_id': '2',
          'username': vm.mobile,
          'password': vm.otp,
          'client_secret': 'WsR5JKy5DJpW1xFFFBf4pgnNNxmBIDkHQRzKFxuQ',


      }).then((res) => {
        vm.$store.dispatch('auth/fetchUser')
        vm.$router.push({name: 'myCheckList.index'});


      }).catch((err) => {
        vm.handleApiError(err)
        // vm.$toast.error({message: vm.$i18n.t('auth.loginFailed')});

      });

    },
  },
  computed: {},

  mixins: [helpers],
  components: {validForm, CustomInput},
};
</script>
