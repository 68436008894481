<template>
  <ValidationObserver ref="form"  tag="div" class="validate-box">
    <form @submit.prevent="validateBeforeSubmit(request,validationError)">
      <slot></slot>
    </form>
  </ValidationObserver>
</template>
<script>
export default {
  props: {
    request: {
      type: Function,
    },
    validationError: {
      type: Function,
      default: () => {
      }
    },

  },
  methods: {

    validateBeforeSubmit(request, validationError=()=>{},form = this.$refs.form) {
      // //save form fields in vuex store to handle validate errors
      this.$store.commit('validation/getFormFields', form)
      // //clear validation errors if they exist berfore
      this.$store.dispatch('validation/setValidateErrors', {})
      if (form) {
        form.validate().then(success => {
          if (!success) {
            return validationError();
          }
          return  request()
        })
      } else return request()
    },
  },

};
</script>
