<template>
  <div class="panel-container team-page">
    <div class="row padding-box">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <tabs  class="row">

            <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <tab :name="$i18n.t('team.member')" type="member" :selected="selectedMember">
                <div class="first">
                  <button class="blue "  @click="addTeam=true"><span class="icon icon-add"></span>{{ $i18n.t('team.newMember') }}</button>
                </div>
                <loadData v-if="$store.state.application.loadings.team.isActive"></loadData>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div  class="table-box">
                      <table class="table" :class="{'blur blur-height':$store.state.application.loadings.team.isActive}">
                        <thead>
                        <tr class="title-row">
                          <th>
                            {{ $i18n.t('common.fullName') }}
                          </th>
                          <th>
                            {{ $i18n.t('team.role') }}
                          </th>
                          <th>
                            {{ $i18n.t('team.position') }}
                          </th>
                          <th>
                            {{ $i18n.t('team.status') }}
                          </th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <memberComponent v-for="team in teamList"   :key="team.id" :team="team"></memberComponent>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>

              </tab>


            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <tab :name="$i18n.t('team.roles')" type="role" :selected="selected">
                <roleComponent>

                </roleComponent>

              </tab>


            </div>



          </tabs>
      </div>
    </div>
    <addTeam v-if="addTeam"></addTeam>

  </div>


</template>

<script>
import roleComponent from "./partials/roleComponent";
import addTeam from "./partials/addTeam";
import memberComponent from './partials/memberComponent'
import loadData from '../partials/form/loadData'

export default {
  name: 'team',
  data(){
    return{
      selectedMember:false,
      selected:false,
      addTeam:false,
      role_id: '',
      job_title_id: '',
      selectedRole: {
        id: '',
        title: ''
      },
      selectedJob: {
        id: '',
        title: ''
      }

    }

  },

  created() {
    this.team();
    this.getList();
    if(this.$route.hash==='#role'){
      return this.selected=true
    }
    else if(this.$route.hash==='#member'){
      return this.selectedMember=true
    }
    else{
      this.selectedMember=true
    }
  },
  computed: {
    teamList() {
      return this.$store.state.team.teamMember || {}
    },
    jobInfoList() {
      let jobTitle = _.filter(this.$store.state.project.jobList, (item) => {
        return item
      });
      return _.map(jobTitle, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },
    roleInfoList() {
      let roleTitle = _.filter(this.$store.state.company.roleList, (item) => {
        return item
      });
      return _.map(roleTitle, function (item) {
        if (item.title !== null) {
          return {
            title: item.title,
            id: item.id,
          };
        } else {
          return {
            title: item.title,
            id: item.id,
          }
        }
      });
    },

  },
  methods:{
    getList() {
      let vm = this;
      let uniqId = this.$route.params.id;

      axios.get ('emp/company/roles').then((response)=> {
        this.$store.state.company.roleList= response.data.data;
      }).catch((error)=> {
      });
      vm.$store.dispatch('project/jobTitleList').then(() => {}).catch(() => {})
    },
    team() {
      let vm = this;
      vm.$store.state.application.loadings.team.isActive=true;
      vm.$store.dispatch('team/teamList').then(() => {
        vm.$store.state.application.loadings.team.isActive=false;
      }).catch(() => {
      })
    }
  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.addTeam = val;
      }
    }
  },
  components: {
    memberComponent,loadData,addTeam,roleComponent
  }
}
</script>
