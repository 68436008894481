<template>
  <modal class="add-user"  :classes="'remove-modal'">
    <div slot="title"></div>
    <div slot="body" ref="form" >
      <div class="row">
        <div class="col-lg-12 col-md-12 center-align">
          <p> {{ $i18n.t('common.removeText') }}</p>
          <button class="white-btn" @click="close">{{ $i18n.t('common.no') }}</button>
          <button class="blue" @click="removeTeam">{{ $i18n.t('common.yes') }}</button>
        </div>
      </div>

    </div>
  </modal>
</template>
<script>

import modal from '../../partials/modal.vue';
import helpers from "../../../../mixin/helpers";


export default {
  name: 'remove',
  data() {
    return {


    };
  },
  created() {

  },

  computed: {


  },

  mounted() {
  },
  methods: {
    close(){
      this.$store.commit('changeModalStatus', false);
    },


  removeTeam() {
    let vm = this;
    let uniqId = vm.$parent.team.id;
    axios.delete('teams/' + uniqId).then((res) => {
      this.close();
    }).catch((err) => {
      vm.handleApiError(err)
    });
  }

  },
  mixins: [helpers],

  components: {
    modal
  }
};
</script>
