<template>
  <div class="panel-container edit-page">
    <div class="row">
      <loadData v-if="$store.state.application.loadings.project.isActive"></loadData>
      <div class="col-lg-8 col-md-8 right"
           :class="{'blur blur-height':$store.state.application.loadings.project.isActive}">
        <valid-form :request="edit">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="upload-img">
                <div></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  v-model="name"
                  :label="$i18n.t('common.fullName')"
                  inputName="name"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  v-model="name"
                  :label="$i18n.t('common.lastName')"
                  inputName="lastName"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convert"
                  v-model="mobile"
                  :label="$i18n.t('auth.mobile')"
                  inputName="mobile"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  v-model="email"
                  :label="$i18n.t('common.email')"
                  inputName="email"
                  rules="required|email"
              />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 address">
              <custom-input
                  :ltr="true"
                  v-model="address"
                  :label="$i18n.t('common.address')"
                  inputName="address"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convertPostalCode"
                  v-model="postalCode"
                  :label="$i18n.t('common.postalCode')"
                  inputName="postalCode"
                  rules="required|digits:10"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convertSsn"
                  v-model="ssn"
                  :label="$i18n.t('common.ssn')"
                  inputName="ssn"
                  :rules="{required: true, regex:/^[\d]{10}$/u}"
              />
            </div>
            <div class="col-lg-12 col-md-12 action-btn">
              <button class="blue">{{ $i18n.t('common.submit') }}</button>
            </div>
          </div>
        </valid-form>

      </div>
      <div class="col-lg-4 col-md-4 left">

      </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "../partials/form/customInput.vue";
import helpers from '../../../mixin/helpers';
import validForm from "../partials/form/valid-form.vue";
import loadData from '../partials/form/loadData'


export default {
  name: 'edit-project',
  data() {
    return {
      address: '',
      ssn: '',
      postalCode: '',
      name: '',
      mobile: '',
      email: '',
      lastName: ''
    }
  },
  created() {
    this.getShowProject();
  },

  methods: {
    convert() {
      this.mobile = this.englishNumber(this.mobile);
    },
    convertPostalCode() {
      this.postalCode = this.englishNumber(this.postalCode);

    },
    convertSsn() {
      this.ssn = this.englishNumber(this.ssn);
    },
    edit() {
      let vm = this;
      let uniqId = vm.$route.params.id;
      axios.put('emp/users/' + uniqId, {
        'mobile': vm.mobile,
        'lastName': vm.lastName,
        'name': vm.name,
        'postal_code': vm.postalCode,
        'address': vm.address,
        'ssn': vm.ssn,
        'email': vm.email,
      }).then((res) => {
        vm.$store.state.auth.user = res;
      }).catch((err) => {
        vm.handleApiError(err)

      });

    },

    getShowProject() {
      let vm = this;
      let uniqId = vm.$route.params.id;

      vm.$store.state.application.loadings.project.isActive = true;
      axios.get('emp/users/' + uniqId).then((res) => {
        vm.$store.state.application.loadings.project.isActive = false;
        let fillData = res.data.data;
        vm.lastName = fillData.lastName;
        vm.mobile = fillData.mobile;
        vm.ssn = fillData.ssn;
        vm.postalCode = fillData.postal_code;
        vm.email = fillData.email;
        vm.address = fillData.address.data.address;
        vm.name = fillData.name;

      }).catch(() => {
      });

    },
  },
  mixins: [helpers],

  components: {CustomInput, validForm, loadData}
}
</script>
