<template>
  <div  id="navigationBar" class="navigation-bar">
    <div class="sidebar-content">
      <div class="close-aside" @click="$store.commit('Toggle')"><p class=" icon icon-close-upload"></p></div>
      <nav class="menu">
        <ul>
          <router-link tag="li" :to="{ name: tab.link.name}"  v-for="(tab, key) in sidebarList"  :key="key" class="menu-item sub-menu"  :id="tab.titleTransKey.replace('.','-')" >
            <a  :href="tab.link.name" @click="$store.commit('Toggle')">
              <i class="menu-icon" :class="tab.icon"></i>
              <span  class="menu-title"> {{ $i18n.t(tab.titleTransKey) }}</span>
            </a>
          </router-link>
        </ul>
      </nav>

    </div>
  </div>
</template>

<script>
export default {
  name: 'navigationBar',
  computed:{
    employee() {
      return this.$store.state.auth.employee;
    },
    user() {
      return this.$store.state.auth.user;
    },
    sidebarList() {
      let vm = this;
      let tabs= vm.$store.state.application.sidebarItem || {};
      return _.filter(tabs, (item) => {
        if (vm.employee===null  && item.group === 'employee') {
          return false;
        }
        if (vm.user===null  && item.group === 'user') {
          return false;
        }
          return true;

      });
    },
  }
}
</script>
