<template>
  <modal class="add-user"  :classes="'remove-modal'">
    <div slot="title"></div>
    <div slot="body" ref="form" >
      <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 center-align">
          <p> {{content}}</p>
          <button class="white-btn" @click="close">{{ $i18n.t('common.no') }}</button>
          <button class="blue" @click="confirmed">{{ $i18n.t('common.yes') }}</button>
        </div>
      </div>

    </div>
  </modal>
</template>
<script>

import modal from '../../partials/modal.vue';
export default {
  name: 'question',
  props: {

    remove: {
      type: String,
      default: '',
    },
    content: {
      type: String,
    },

  },
  data() {
    return {


    };
  },

  methods: {
    confirmed() {
      this.$emit('confirmed');
    },
    close(){
      this.$store.commit('changeModalStatus', false);
    },

  },

  components: {
    modal
  }
};
</script>