<template>
  <div class="panel-container edit-page">
    <div class="row">

      <div class="col-lg-8 col-md-8 right">
        <valid-form :request="edit">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="upload-img">
                <div></div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  v-model="name"
                  :label="$i18n.t('common.fullName')"
                  inputName="name"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convertSsn"
                  v-model="ssn"
                  :label="$i18n.t('common.ssn')"
                  inputName="ssn"
                  :rules="{required: true, regex:/^[\d]{10}$/u}"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convert"
                  v-model="mobile"
                  :label="$i18n.t('auth.mobile')"
                  inputName="mobile"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  v-model="email"
                  :label="$i18n.t('common.email')"
                  inputName="email"
                  rules="required|email"
              />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 address">
              <custom-input
                  :ltr="true"
                  v-model="address"
                  :label="$i18n.t('common.address')"
                  inputName="address"
                  rules="required"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <custom-input
                  :ltr="true"
                  @input="convertPostalCode"
                  v-model="postalCode"
                  :label="$i18n.t('common.postalCode')"
                  inputName="postalCode"
                  rules="required|digits:10"
              />
            </div>

            <div class="col-lg-12 col-md-12 divider">
            </div>
            <div class="col-lg-6 col-md-6">
              <p>{{ $i18n.t('edit.doc') }}</p>
            </div>
            <div class="col-lg-6 col-md-6 action-btn">
              <!--              <button class="blue">{{ $i18n.t('edit.uploadDoc') }}</button>-->
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 vertical">
              <p class="upload-text">۱- {{ $i18n.t('edit.1') }}</p>
              <div class="file" v-if="national_card_asset_id"><span class="name fa-number">{{national_card_asset_id |strLimit(20)}}</span> <span class="icon icon-close close-upload" @click="remove('national_card_asset_id')"></span></div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 left">
              <p class=" btn gray-btn" v-if="uploading.national_card.state === 0"
                 >
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class=" btn error" v-if="uploading.national_card.state === -1">
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class="btn success" v-if="uploading.national_card.state === 2">
                <span class="icon icon-close-upload"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>
              <p class="btn uploading" v-if="uploading.national_card.state === 1">
                <span class="icon icon-load"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>
              <input @change="uploadFile('national_card')" id="national_card" name="national_card" ref="national_card"
                     type="file">
              <p class="type-error" v-if="uploading.national_card.state === -1">{{$i18n.t('edit.errorTypeDocument1')}}</p>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 vertical">
              <p class="upload-text">۲- {{ $i18n.t('edit.2') }}</p>
              <div class="file" v-if="birth_certificate_asset_id"><span class="name fa-number">{{birth_certificate_asset_id |strLimit(20)}}</span> <span class="icon icon-close close-upload" @click="remove('birth_certificate_asset_id')"></span></div>

            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 left">
              <p class=" btn gray-btn" v-if="uploading.birth_certificate.state === 0"
                 >
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class=" btn error" v-if="uploading.birth_certificate.state === -1">
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class="btn success" v-if="uploading.birth_certificate.state === 2">
                <span class="icon icon-close-upload"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>
              <p class="btn uploading" v-if="uploading.birth_certificate.state === 1">
                <span class="icon icon-load"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>

              <input @change="uploadFile('birth_certificate')" id="birth_certificate" name="birth_certificate"
                     ref="birth_certificate" type="file">
              <p class="type-error" v-if="uploading.birth_certificate.state === -1">{{$i18n.t('edit.errorTypeDocument1')}}</p>

            </div>

            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 vertical">
              <p class="upload-text">۳- {{ $i18n.t('edit.3') }}</p>
              <div class="file" v-if="no_criminal_record_asset_id"><span class="name fa-number">{{no_criminal_record_asset_id |strLimit(20)}}</span> <span class="icon icon-close close-upload" @click="remove('no_criminal_record_asset_id')"></span></div>


            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 left">
              <p class=" btn gray-btn" v-if="uploading.criminal_record.state === 0"
                 >
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class=" btn error" v-if="uploading.criminal_record.state === -1">
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class="btn success" v-if="uploading.criminal_record.state === 2">
                <span class="icon icon-close-upload"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>
              <p class="btn uploading" v-if="uploading.criminal_record.state === 1">
                <span class="icon icon-load"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>

              <input @change="uploadFile('criminal_record')" id="criminal_record" name="criminal_record"
                     ref="criminal_record" type="file">
              <p class="type-error" v-if="uploading.criminal_record.state === -1">{{$i18n.t('edit.errorTypeDocument1')}}</p>

            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 vertical">
              <p class="upload-text">۴- {{ $i18n.t('edit.4') }}</p>
              <div class="file" v-if="education_asset_id"><span class="name fa-number">{{education_asset_id |strLimit(20)}}</span> <span class="icon icon-close close-upload" @click="remove('education_asset_id')"></span></div>

            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12 left">
              <p class=" btn gray-btn" v-if="uploading.education.state === 0"
                 >
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class=" btn error" v-if="uploading.education.state === -1">
                <span class="icon icon-upload-white"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}
              </p>
              <p class="btn success" v-if="uploading.education.state === 2">
                <span class="icon icon-close-upload"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>
              <p class="btn uploading" v-if="uploading.education.state === 1">
                <span class="icon icon-load"></span>
                {{ $i18n.t('myCheckList.uploadFile') }}</p>

              <input @change="uploadFile('education')" id="education" name="education" ref="education" type="file">
              <p class="type-error" v-if="uploading.education.state === -1">{{$i18n.t('edit.errorTypeDocument1')}}</p>


            </div>
            <div class="col-lg-12 col-md-12 action-btn">
              <button class="blue">{{ $i18n.t('common.submit') }}</button>
            </div>
          </div>
        </valid-form>

      </div>
      <div class="col-lg-4 col-md-4 left">

      </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "../partials/form/customInput.vue";
import helpers from '../../../mixin/helpers';
import validForm from "../partials/form/valid-form.vue";
import loadData from '../partials/form/loadData'


export default {
  name: 'edit-project',
  data() {
    return {
      address: '',
      ssn: '',
      postalCode: '',
      birth_certificate_asset_id: '',
      national_card_asset_id: '',
      no_criminal_record_asset_id: '',
      education_asset_id: '',
      name: '',
      mobile: '',
      email: '',
      lastName: '',
      uploading: {
        isUploading: false,
        education: {
          state: 0,
          name: null,
          file: null,
        },
        criminal_record: {
          state: 0,
          name: null,
          file: null,
        },
        birth_certificate: {
          state: 0,
          name: null,
          file: null,
        },
        national_card: {
          state: 0,
          name: null,
          file: null,
        },
      },
    }
  },
  created() {
    this.fillData();
    if(!this.$store.state.auth.userExist){
      this.$router.push({name: 'project.index'}).catch(() => {
      });
    }

  },

  computed: {
    user() {
      return this.$store.state.auth.user || {}
    },
  },
  methods: {
    convert() {
      this.mobile = this.englishNumber(this.mobile);
    },
    convertPostalCode() {
      this.postalCode = this.englishNumber(this.postalCode);

    },
    convertSsn() {
      this.ssn = this.englishNumber(this.ssn);
    },
    remove(value){
      if(value==='national_card_asset_id'){
        this.uploading['national_card'].file=null;
        this.national_card_asset_id=''

      }
      else  if(value==='birth_certificate_asset_id'){
        this.uploading['birth_certificate'].file=null;
        this.birth_certificate_asset_id=''

      }
      else  if(value==='no_criminal_record_asset_id'){
        this.uploading['criminal_record'].file=null;
        this.no_criminal_record_asset_id=''

      }
      else  if(value==='education_asset_id'){
        this.uploading['education'].file=null;
        this.education_asset_id=''

      }
    },
    edit() {
      let vm = this;
      axios.put('me', {
        'mobile': vm.mobile,
        'lastName': vm.lastName,
        'name': vm.name,
        'postal_code': vm.postalCode,
        'address': vm.address,
        'ssn': vm.ssn,
        'email': vm.email,
        "national_card_asset_id": vm.uploading['national_card'].file?.id,
        "education_asset_id": vm.uploading['education'].file?.id,
        "birth_certificate_asset_id": vm.uploading['birth_certificate'].file?.id,
        "no_criminal_record_asset_id": vm.uploading['criminal_record'].file?.id
      }).then((res) => {
        vm.$store.state.auth.user = res.data.data;
        vm.$router.push({name: 'myCheckList.index'}).catch(() => {
        })
      }).catch((err) => {
        vm.handleApiError(err)

      });

    },
    fillData() {
      let vm = this;
      vm.lastName = vm.user.name;
      vm.mobile = vm.user.mobile;
      vm.ssn = vm.user.ssn;
      vm.postalCode = vm.user.postal_code;
      vm.email = vm.user.email;
      vm.address = vm.user.address;
      vm.name = vm.user.name;
      vm.national_card_asset_id = vm.user.national_card_asset_id;
      vm.birth_certificate_asset_id = vm.user.birth_certificate_asset_id;
      vm.no_criminal_record_asset_id = vm.user.no_criminal_record_asset_id;
      vm.education_asset_id = vm.user.education_asset_id;

    },
    uploadFile(name) {
      let vm = this;
      if (vm.$refs[name].files.length === 0) {
        return
      }
      let type = vm.$refs[name].files[0].type;
      if (this.$refs[name].files.length > 0 && (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||type === 'application/x-rar' ||type === 'application/zip' ||type === 'image/png' ||type === 'application/pdf' || type === 'image/jpg' || type === 'image/jpeg'||type === 'application/vnd.ms-excel'||type === 'application/msword'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        vm.uploading[name].state = 1;
        vm.uploading.isUploading = true;
        this.selectFileToUpload(vm.$refs[name].files, 'document',
            function (response) {
              vm.logo = response.data.data;
              vm.uploading[name].file = response.data.data;
              vm.uploading[name].state = 2;
              vm.uploading.isUploading = false;

            }, function (error) {
              vm.uploading[name].state = -1;
              vm.uploading[name].name = vm.errors.items[0].msg;
              vm.uploading.isUploading = false;
            });

      } else {
        vm.uploading[name].state = -1;
      }
    },
  },
  mixins: [helpers],

  components: {CustomInput, validForm, loadData}
}
</script>
