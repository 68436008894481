<template>
  <div class="single">
    <div class="right-align inline-block">
      <span class="icon task-title icon-check">{{ task.title |strLimit(50) }}</span>
    </div>
    <div class="left-align  inline-block icon-box">
      <span class="icon icon-clock"></span>
      <a v-if="task.link" :href="task.link" target="_blank">
        <span class="icon icon-attach-link"></span>
      </a>
      <a class="icon icon-copy" v-if="taskDownload"  :href="taskDownload.links.full" target="_blank" :download="taskDownload.links.full"></a>
      <span @click="getId(task)" class="icon icon-more-gray pointer" v-popover.bottom="{ name: 'editSetting' }"></span>

    </div>
    <div class="row">

      <p class="col-lg-10 col-md-10 col-xs-10 col-sm-10 description text" v-if="openBox">
        {{ task.description }}
      </p>
      <p class="col-lg-10 col-md-10 col-xs-10 col-sm-10  description" v-else>
        {{ task.description |strLimit(90) }}
      </p>
      <div class="col-lg-1 col-md-1 col-xs-1 col-sm-1 left-align  inline-block ">

        <span v-if="lengthOfDescription>90" :class="{'icon-up':openBox,'icon-down':!openBox}" class="pointer icon "
              @click="openBox = !openBox"></span>

      </div>

    </div>

  </div>
</template>
<script>
import helpers from '../../../../mixin/helpers';

export default {
  name: 'single_task',
  props: ['task'],
  data() {
    return {
      openBox: false,
    };
  },
  created() {

  },
  computed: {
    parent() {
      return this.$parent.$parent.$parent;
    },
    lengthOfDescription() {
      return this.task.description.length;
    },
    taskDownload() {
      return this.task.company_assets.data[0];
    },
  },
  methods: {
    getId(value) {
      this.parent.userId = value.id;
      this.parent.checkListId = value.checklist_id;
      this.parent.checkList = false;

    },

  },

  mixins: [helpers],
  components: {},
};
</script>
