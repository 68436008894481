export default {

    methods: {
        englishNumber(value) {
            let vm = this;
            //convert farsi to eng
            if (value.length > 0) {
                value = value.replace(/[\u0660-\u0669]/g, function (c) {
                    return c.charCodeAt(0) - 0x0660;
                }).replace(/[\u06f0-\u06f9]/g, function (c) {
                    return c.charCodeAt(0) - 0x06f0;
                });
            }
            return value.replace(/[^\d]/g, '');
        },
        handleApiError(validationErrors, form = this.$refs.form) {
            let vm = this;
            let errorObject = validationErrors.response.data;
            if (errorObject.errors) {
                for (const [key, value] of Object.entries(errorObject.errors)) {
                    let error = `${key}: ${value}`;
                    let msg = window.fa.messages[error];
                    vm.$toast.error({message: msg});
                    // let errorObj = {};
                    // errorObj= msg;
                    // this.$refs.form.$refs.form.setErrors(errorObj);

                }
            }
            else if(errorObject.message==='REST_USER_NOT_FOUND'){
                vm.$toast.error({message: this.$i18n.t('auth.notUser')});

            }
            else if(errorObject.error_description==='The user credentials were incorrect.'){
                vm.$toast.error({message: this.$i18n.t('auth.notCorrect')});

            }

        },
    },


}
