<template>
  <ValidationProvider :name="label" :vid="inputName" :rules="rules" v-slot="{ errors }">
    <label v-if="hasLabel" :for="inputName">{{ label }}</label>
    <input
        :type="type"
        :readonly="readonly"
        :name="inputName"
        :value="value"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
        :class="[{'input-error':errors[0]},newClass,{'ltr':ltr},{'read-only-input':readonly}]"
        :disabled="disabled"
    />
    <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  name: "customInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    ltr: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: [String, Number],
    inputName: {
      type: String,
    },
    rules: {
      type: [String, Object],
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    newClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    name() {
      return this.label.toLowerCase();
    },
  },
};
</script>
