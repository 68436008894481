<template>
  <div class="row initial-page">
    <div class="col-lg-8 col-lg-offset-3 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 auto-margin">
      <p class="title">{{ $i18n.t('auth.loginToSystem') }}</p>
      <p class="sub-title">{{ $i18n.t('auth.information') }}</p>
      <valid-form :request="initial">
        <custom-input
            :ltr="true"
            v-model="email"
            :label="$i18n.t('common.email')"
            inputName="email"
            rules="email"
        />
        <custom-input
            :ltr="true"
            @input="convert"
            v-model="mobile"
            :label="$i18n.t('auth.mobile')"
            inputName="mobile"
            rules="required"
        />
        <div class="button-submit">

          <button class="auth-btn" >{{ $i18n.t('auth.getOtp') }}</button>
        </div>
      </valid-form>
    </div>
  </div>
</template>
<script>
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import helpers from '../../../../mixin/helpers';

export default {
  name: 'auth_loginInit',
  data() {
    return {
      mobile: null,
      email: null,

    };
  },

  methods: {
    convert() {
      this.mobile = this.englishNumber(this.mobile);
    },
    initial(){
      this.$parent.initRequest(this.mobile,this.email)
    },
  },
  mixins: [helpers],
  components: {validForm, CustomInput},
};

</script>
