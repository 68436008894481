<template>
  <div>
    <div  class="single-project " @click="selectItem"
         :class="{'in-process':inProcessStatus,'done':doneStatus,'pending':pendingStatus}">
      <router-link tag="span" :to="{name: 'myCheckList.index', params: {id: this.task.id}}" class="arrow-down" v-popover.bottom="{ name: 'foo' }" ></router-link>
      <span class="icon-status"   ></span>
      <span class="right-align" >{{ task.title | strLimit(36) }}</span>
      <span class="left-align" >{{ task.created_at|jalali|persianNumbers }}</span>

    </div>
  </div>
</template>

<script>
export default {
  name: 'single-project',
  props: ['task']
  ,

  computed: {

    doneStatus() {
      if (this.task.status === 2) {
        return true
      }
    },
    pendingStatus() {
      if (this.task.status === 0) {
        return true
      }
    },
    inProcessStatus() {
      if (this.task.status === 1) {
        return true
      }
    }
  },

  methods: {


    selectItem() {
      this.$router.push({name: 'myCheckList.index', params: {id: this.task.id}}).catch(() => {
      })
    },

  },

}
</script>
