<template>
  <div class="basic-info">
    <tabs class="row">

      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
        <tab :name="$i18n.t('common.department')" type="department" :selected="selecteTab.department">
          <div class="col-lg-11 col-md-12 col-xs-12 col-sm-12 ">
            <loadData v-if="$store.state.application.loadings.project.isActive"></loadData>
          </div>
          <valid-form :request="add" ref="form">
            <div class="row department-part">
              <div class="col-lg-5 col-md-5 col-xs-12 col-sm-5">
                <custom-input
                    ref="role"
                    v-model="title"
                    :label="$i18n.t('setting.departmentTitle')"
                    inputName="title"
                    rules="required"
                />
              </div>
              <div class="col-lg-5 col-md-5 col-xs-12 col-sm-5">
                <validation-provider ref="teamList" :name="$i18n.t('project.add.name1')" rules='required'
                                     v-slot="{ errors }"
                                     class="form-group">
                  <label>{{ $i18n.t('project.add.name1') }}</label>
                  <v-select
                          dir="rtl"
                      :options='ownerList'
                      v-model=selectedTeam
                      @search:blur="getUserId"

                      :class="{'input-error':errors[0]}"
                      label="title">
                  </v-select>
                  <span v-if="errors[0]" class="text-error">{{ errors[0] }}</span>

                </validation-provider>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 action-btn">
                <button class="gray">
                  <span class="icon icon-add"></span>
                </button>
              </div>
            </div>

          </valid-form>


          <div class="col-lg-11 col-md-12 col-xs-12 col-sm-12 basic-info">

            <div class="row department-info" :class="{'blur ':$store.state.application.loadings.project.isActive}"
                 v-for="item in department" :key="item.id">
              <div class="col-lg-4  col-md-4 col-sm-4 col-xs-4" v-bind:class="{'selected': current === item.id}">
                <custom-input v-if="current === item.id"
                              ref="role"
                              v-model="name"
                              inputName="name"
                              rules="required"
                />
                <p v-else class="name">{{ item.title }}</p>
              </div>
              <div class="col-lg-6  col-md-6 col-sm-6 col-xs-8" v-bind:class="{'selected': current === item.id}"
                   v-for="owner in ownerName[item.user_id]||[]" :owner="owner"
                   :key="owner.id">
                <!--<img class="avatar" v-if="owner.avatar" :src="owner.avatar">-->

                <div class="xs">
                  <v-select v-if="current === item.id"
                            dir="rtl"
                            :options='ownerList'
                            v-model=selectedOwner
                            @search:blur="ownerId()"
                            label="title">
                  </v-select>
                  <div v-else>
                    <img class="owner-avatar"
                         src="images/user.svg">
                    <span class="name">{{ owner.name }} </span>
                  </div>
                </div>

              </div>
              <div class="col-lg-2  col-md-2 col-xs-12 col-sm-2">

                <span class="icon icon-mark pointer"  v-if="current === item.id" @click="update()" ></span>
                <span class="icon icon-edit pointer"  v-else @click="setCurrent(item.id)"></span>

                <span class="icon close pointer"  v-if="current === item.id" @click="cancel()" ></span>
                <span class="icon icon-trash  pointer"  v-else @click="getId(item.id)"></span>
              </div>
            </div>


          </div>
        </tab>
      </div>
      <div class="col-lg-7 col-md-7 col-xs-12 col-sm-12">
        <tab :name="$i18n.t('common.other')" type="other"
             :selected="selecteTab.other">
        </tab>
      </div>
    </tabs>
    <question v-if="question" @confirmed="remove()" ref="RemoveQuestion" :content=" $i18n.t('setting.removeDepartment')"
              :remove="$i18n.t('common.yes')"></question>

  </div>
</template>
<script>
import question from "../../partials/form/question";
import validForm from "../../partials/form/valid-form.vue";
import CustomInput from "../../partials/form/customInput.vue";
import helpers from "../../../../mixin/helpers";
import loadData from '../../partials/form/loadData'

export default {
  name: 'basic_info',
  data() {
    return {
      user_id: '',
      name: '',
      editList: '',
      edit: false,
      current: null,
      question: false,
      department_id: '',

      title: '',
      selecteTab: {
        other: false,
        department: false,

      },
      selectedOwner: null,
      selectedTeam: null,
    }
  },
  created() {
    this.tab();
    this.fetchDepartment();
    this.fetchTeam();
  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.question = val;
      }
    }
  },
  computed: {
    ownerList() {
      let team = _.filter(this.$store.state.team.teamMember, (item) => {
        return item
      });
      return _.map(team, function (item) {
        if (item.title !== null) {
          return {
            title: item.name,
            id: item.id,
          };
        } else {
          return {
            title: item.name,
            id: item.id,
          }
        }
      });
    },
    department() {
      return this.$store.state.project.departmentList
    },
    ownerName() {
      let item = _.filter(this.$store.state.team.teamMember, 'id')
      let owner = _.groupBy(item, 'id')
      return owner
    }


  },
  methods: {
    getId(id) {
      this.department_id = id;
      this.question = true;
    },
    update() {
      let vm=this;
      axios.put('emp/departments', {
        "department_id": vm.department_id,
        "user_id": vm.user_id,
        "title": vm.name,

      }).then((res) => {
        vm.edit = false;
        vm.current = null;
        vm.$toast.success({title: vm.$i18n.t('setting.updateDepartment')});
      }).catch((err) => {
        // vm.handleApiError(err)
      });
    },
    setCurrent(id) {
      this.edit = true;
      this.current = id;
      this.department_id = id;
      let select=_.filter(this.$store.state.project.departmentList, ['id',id])
      this.name = select[0].title;

    },
    cancel() {
      this.edit = false;
      this.current = null;
    },
    tab() {
      if (this.$route.hash === '#department') {
        return this.selecteTab.department = true
      } else if (this.$route.hash === '#other') {
        return this.selecteTab.other = true
      } else {
        this.selecteTab.department = true
      }
    },
    remove() {
      let vm = this;
      axios.delete('emp/departments/' + vm.department_id).then((res) => {
        vm.$store.commit('changeModalStatus', false);
        vm.$toast.success({title: vm.$i18n.t('setting.successRemoveDepartment')});

      }).catch((err) => {
        vm.handleApiError(err)
      });
    },
    fetchTeam() {
      let vm = this;
      vm.$store.state.application.loadings.team.isActive = true;
      vm.$store.dispatch('team/teamList').then(() => {
        vm.$store.state.application.loadings.team.isActive = false;
      }).catch(() => {
      });
    },
    fetchDepartment() {
      let vm = this;
      vm.$store.state.application.loadings.project.isActive = true;
      vm.$store.dispatch('project/departmentList').then(() => {
        vm.$store.state.application.loadings.project.isActive = false;
      }).catch(() => {
      })
    },
    getUserId() {
      this.user_id = this.selectedTeam.id
    },
    ownerId() {
      this.user_id = this.selectedOwner.id
    },



    add() {
      let vm = this;
      axios.post('emp/departments', {
        'title': vm.title,
        'user_id': vm.user_id,
      }).then((response) => {
        vm.$toast.success({title: vm.$i18n.t('setting.successDepartment')});
      }).catch((err) => {
        vm.handleApiError(err)
      })

    }
  },

  mixins: [helpers],
  components: {
    validForm, CustomInput, loadData, question
  }
};
</script>
