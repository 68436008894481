<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
      <topBar></topBar>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="display: flex">
          <div class="aside" :class="{'toggle-mobile': $store.state.application.mobileSidebarToggle,'sidebar':$store.state.application.sidebarToggle}">
            <navigationBar
                ></navigationBar>
          </div>
          <router-view class="layout-box"></router-view>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import topBar from './partials/topBar';
import navigationBar from './partials/navigationBar';

export default {
  name: 'home',
  components: {
    navigationBar, topBar
  }
}
</script>
