export default {
  namespaced: true,
  state: {
    user: null,
    employee: null,
    employeeExist:false,
    userExist:false,
    secondRequest:0,
  },
  getters: {
    check(state) {
      return state.user !== null;
    },
    loginState(state) {
      if(state.userExist && state.employeeExist){
        return 'both'

      }
       if(state.userExist && !state.employeeExist){
        return 'user'
      }
       if(!state.userExist && state.employeeExist){
        return 'employee'
      }
      return 'unAuthorization'

    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setEmployee(state, employee) {
      state.employee = employee;
    },
    check_user(state, userExist) {
      state.userExist = userExist;
    },
    check_employee(state, employeeExist) {
      state.employeeExist = employeeExist;
    },

    check_2(state, secondRequest) {
      state.secondRequest = secondRequest;
    },
  },

  actions: {
    fetchEmployee({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/teams/me').then(function(response) {
          commit('setEmployee', response.data.user);
          commit('check_employee', true);
          commit('check_2', 2);

          resolve();
        }).catch(function(error) {
          commit('check_employee', false);
          commit('check_2', 2);

          reject(error);
        });
      });
    },

    fetchUser({commit}) {
      commit(
          'loadingStatus',
          {name: 'auth', 'status': true},
          {root: true},
      );
      return new Promise((resolve, reject) => {
        axios.get ('me').then(function(response) {

          commit('setUser', response.data.data);
          commit('check_user', true);
          commit('check_2', 1);

          resolve();
          axios.get ('emp/teams/me').then(function(response) {
            commit(
              'loadingStatus',
              {name: 'auth', 'status': false},
              {root: true},
            );
            commit('setEmployee', response.data.user);
            commit('check_employee', true);
            commit('check_2', 2);

            resolve();
          }).catch(function(error) {
            commit(
              'loadingStatus',
              {name: 'auth', 'status': false},
              {root: true},
            );
            commit('check_employee', false);
            commit('check_2', 2);

            reject(error);
          });
        }).catch(function(error) {
          commit(
              'loadingStatus',
              {name: 'auth', 'status': false},
              {root: true},
          );
          commit('check_user', false);
          commit('check_2', 1);


          reject(error);
          axios.get ('emp/teams/me').then(function(response) {
            commit('setEmployee', response.data.user);
            commit('check_employee', true);
            commit('check_2', 2);

            resolve();
          }).catch(function(error) {
            commit('check_employee', false);
            commit('check_2', 2);

            reject(error);
          });
        });
      });
    },

  },
};
