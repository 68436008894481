<template>
  <div class="detail-project row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <p class="title medium-font">{{ detailProject.title |strLimit(60) }}</p>
      <p class="time medium-font">{{ $i18n.t('myCheckList.deadline') }}{{
          detailProject.updated_at|jalali|persianNumbers
        }} </p>
      <p class="description">{{ detailProject.description }}</p>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b-60">
      <p class="lightGray">{{ $i18n.t('myCheckList.receiver') }}</p>
<!--      <img class="avatar" v-if="ownerTask.avatar">-->
      <div class="avatar-load" ></div>

      <span class="medium-font user-name lightGray" v-if="ownerTask.name">{{ ownerTask.name }}</span>
      <span class="medium-font user-name lightGray" v-else>--</span>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-4 col-xs-12 upload">
      <button class=" btn white-btn" v-if="uploading.fileId.state === 0"
      >
        <span class="icon icon-upload"></span>
        {{ $i18n.t('myCheckList.uploadFile') }}
      </button>
      <button class=" btn error" v-if="uploading.fileId.state === -1">
        <span class="icon icon-upload-white"></span>
        {{ $i18n.t('myCheckList.uploadFile') }}
      </button>
      <button class="btn success" v-if="uploading.fileId.state === 2">
        <span class="icon icon-close-upload"></span>
        {{ $i18n.t('myCheckList.uploadFile') }}</button>
      <button class="btn uploading" v-if="uploading.fileId.state === 1">
        <span class="icon icon-load"></span>
        {{ $i18n.t('myCheckList.uploadFile') }}</button>
      <input @change="uploadFile('fileId')" id="fileId" name="fileId"
             ref="fileId" type="file">
      <p class="type-error" v-if="uploading.fileId.state === -1">{{$i18n.t('edit.errorTypeDocument1')}}</p>


    </div>
    <div class="col-lg-4 col-md-12 col-sm-4 col-xs-12" v-if="companyDownload">
      <a :href="companyDownload.links.full" target="_blank" :download="companyDownload.links.full"
         >
        <button class="white-btn">
          <span class="icon icon-download"></span>
          {{ $i18n.t('myCheckList.downloadFile') }}
        </button>
      </a>

    </div>
    <div class="col-lg-4 col-md-12 col-sm-4 col-xs-12" v-if="detailProject.link">
      <a :href="detailProject.link" target="_blank" >
        <button class="white-btn">
          <span class="icon icon-attach"></span>
          {{ $i18n.t('myCheckList.attachLink') }}
        </button>
      </a>

    </div>
  </div>
</template>

<script>
export default {
  name: 'single-project',
  props: {
    detailProject: {}
  },
  data() {
    return {
      uploading: {
        isUploading: false,
        isValidate: false,
        fileId: {
          state: 0,
          name: null,
          file: null,
        },
      },

    }
  },
  computed: {
    ownerTask() {
      return this.detailProject.owner.data
    },
    companyDownload() {
      return this.detailProject.company_assets.data[0]
    },
  },
  methods: {
    uploadFile(name) {
      let vm = this;
      if (vm.$refs[name].files.length === 0) {
        return
      }
      let type = vm.$refs[name].files[0].type;
      if (this.$refs[name].files.length > 0 && (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||type === 'application/x-rar' ||type === 'application/zip' ||type === 'image/png' ||type === 'application/pdf' || type === 'image/jpg' || type === 'image/jpeg'||type === 'application/vnd.ms-excel'||type === 'application/msword'||type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        vm.uploading[name].state = 1;
        vm.uploading.isUploading = true;
        this.selectFileToUpload(vm.$refs[name].files, 'document',
            function (response) {
              vm.uploading[name].file = response.data.data;
              vm.uploading[name].state = 2;
              vm.uploading.isUploading = false;
              let uniqId = vm.$route.params.id;
              let assetId = response.data.data.id;
              axios.put('tasks/' + uniqId, {
                'asset_id': assetId,
              }).then((res) => {
              }).catch((err) => {
              });

            }, function (error) {
              vm.uploading[name].state = -1;
              vm.uploading[name].name = vm.errors.items[0].msg;
              vm.uploading.isUploading = false;
            });

      } else {
        vm.uploading[name].state = -1;
      }
    },
  }

}
</script>
