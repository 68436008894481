import Vuex from 'vuex';

window.store = new Vuex.Store({});
window.store.registerModule('application', require('./modules/application').default);
window.store.registerModule('validation', require('./modules/validation'));
window.store.registerModule('auth', require('./modules/auth').default);
window.store.registerModule('project', require('./modules/project').default);
window.store.registerModule('team', require('./modules/team').default);
window.store.registerModule('company', require('./modules/company').default);
window.store.registerModule('task', require('./modules/task').default);
window.store.registerModule('setting', require('./modules/setting').default);

