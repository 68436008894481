import Vue from "vue";

Vue.component('tabs', {
    template: `
    <div>
      <div class="tabs ">
        
        <ul>
          <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }">
            <a  class="medium-font" :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
          </li>
        </ul>

      </div>
      <slot name="button"></slot>

      <div class="tabs-details">
        <slot></slot>
      </div>
    </div>
  `,
    data() {
        return { tabs: [] }
    },
    created() {
        this.tabs = this.$children;


    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name === selectedTab.name);
            });
        }
    }
});

Vue.component('tab', {
    props: {
        name: { required: true },
        type: { required: true },
        selected: { default: false }
    },
    template: `<div v-show="isActive"><slot></slot></div>`,
    data() {
        return { isActive: false }
    },
    computed: {
        href() {

            return '#' + this.type;
        }
    },

  mounted() {
        this.isActive = this.selected;
    }
});
