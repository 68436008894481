export default {
  namespaced: true,
  state: {
    welcome: '',
  },

  mutations: {

    set_welcome(state, welcome) {
      state.welcome = welcome;
    },
  },
  actions: {
    getRoleList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/settings').then(function(response) {
          commit('set_welcome', response.data.data);
          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });
    },

  },
};
