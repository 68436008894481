<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <router-view></router-view>
    </div>
  </div>


</template>
<script>
import helpers from '../../../../mixin/helpers';

export default {
  name: 'auth-login',
  methods: {

    initRequest (mobile, email) {
      let vm = this;
      axios.post ('oauth/init', {
        'mobile': mobile,
        'email': email,
        'client_id': 2,
      }).then((response) => {
        vm.$router.push({name: 'auth.loginRequest',params: {
            email: email,
            mobile: mobile,
            waitingTime: response.data.waiting_time,
            otpTimeDiff: response.data.otp_time_diff,
          },
        }).catch(err => {});
      }).catch((err) => {
        this.handleApiError(err)
      })
    },

  },
  mixins: [helpers],

}
</script>
