<template >
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 loading-data">
      <div class="loading">
        <p class="loading-bro">
          <svg id="load"  x='0px' y='0px' viewBox='0 0 150 150'>
            <circle id="loading-inner" cx='75' cy='75' r='60'></circle>
          </svg>
        </p>
        <p  class="text">{{$i18n.t('common.loading')}}</p>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'load-data',
  props:{
    storeName:''
  }

};
</script>
