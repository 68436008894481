<template>
  <modal :classes="'add-task'">
    <div class="header" slot="title">
      <p>{{ $i18n.t('setting.task.edit') }}</p>
    </div>
    <div slot="body">
      <div class="row" :class="{'blur':$store.state.application.loadings.task.isActive}">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div class="type-box" v-for="item in typeList" :key="item.id">
            <input type="checkbox" :name="item.title" v-model="checkedNames" :value="item.id"
                   :checked="checked(item.id)">
            <label>{{ item.title }}</label>

          </div>
        </div>
        <valid-form :request="updateTask" ref="form">
          <typeOne @event="updateTypeOne" v-if="one" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                   ref="type_one"></typeOne>
          <typeTwo @input="updateTypeTwo" v-if="two" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                   ref="type_Two"></typeTwo>
          <div class="col-lg-12 col-md-12 action-btn">
            <button class="blue">{{ $i18n.t('setting.task.edit') }}</button>
          </div>
        </valid-form>
      </div>
      <br>

    </div>
  </modal>
</template>
<script>
import modal from '../../partials/modal.vue';
import helpers from '../../../../mixin/helpers';
import CustomInput from '../../partials/form/customInput.vue';
import validForm from '../../partials/form/valid-form.vue';
import typeOne from './typeOne';
import typeTwo from './typeTwo';

export default {
  name: 'edit_task',
  data() {
    return {
      type: '', activeOne: false,
      activeTwo: false, checkedNames: [],

    };
  },
  created() {
    this.fillData();
    this.$store.dispatch('task/typeList');
  },
  computed: {
    typeList() {
      return this.$store.state.task.typeList;
    },
    list() {
      return this.$store.state.task.list;
    },
    one() {
      return this.activeOne;
    },
    two() {
      return this.activeTwo;
    },
  },
  methods: {
    fillData() {
      let vm = this;
      let uniqId = vm.$parent.userId;
      vm.$store.state.application.loadings.task.isActive = true;
      axios.get('emp/tasks/' + uniqId).then((response) => {
        vm.$store.commit('task/set_List', response.data.data);
        vm.$store.state.application.loadings.task.isActive = false;

        let typeOne = this.$refs.type_one;
        let fill = this.$store.state.task.list;
        typeOne.link = fill.link,
            typeOne.title = fill.title,
            typeOne.description = fill.description,
            typeOne.time = fill.expire_in;
        let expireIn = _.filter(typeOne.dateList, ['id', fill.expire_in]);
        typeOne.selectedTime = expireIn[0].title;
        this.checkedNames.push(parseInt(fill.type_id));
      }).catch((err) => {
        vm.handleApiError(err);
      });
    },
    checked(id) {
      let vm = this;

      if (vm.checkedNames.indexOf(1) !== -1 && vm.checkedNames.indexOf(2) === -1) {
        vm.activeOne = true;
      }
      if (vm.checkedNames.indexOf(2) !== 1) {
        vm.activeTwo = false;
        vm.activeOne = true;

      }
      if (vm.checkedNames.indexOf(1) === -1 && vm.checkedNames.indexOf(2) !== -1) {
        vm.activeOne = false;
        vm.activeTwo = true;
      }
      if (vm.checkedNames.indexOf(1) !== -1 && vm.checkedNames.indexOf(2) !== -1) {
        vm.activeOne = true;
        vm.activeTwo = true;

      }

    },

    updateTypeOne() {
      let vm = this;
      if (this.$route.hash === '#company_actions') {
        vm.type_id = '2';
        vm.is_personal = '0';
      } else if (this.$route.hash === '#employee_actions') {

        vm.type_id = '1';
        vm.is_personal = '1';
      }
      let uniqId = vm.$parent.userId;
      axios.put('emp/tasks/' + uniqId, {
        'link': vm.$refs.type_one.link,
        'title': vm.$refs.type_one.title,
        'description': vm.$refs.type_one.description,
        'asset_id': vm.$refs.type_one.uploading['fileId'].file?.id,
        'type_id': '1',
        'expire_in': vm.$refs.type_one.time,
        'checklist_id': vm.$parent.checkListId,
      }).then((response) => {
        this.$store.dispatch('task/checkList');
        vm.$store.commit('changeModalStatus', false);

      }).catch((err) => {
        vm.handleApiError(err);
      });
    },
    updateTypeTwo() {
      let vm = this;
      if (this.$route.hash === '#company_actions') {
        vm.type_id = '2';
        vm.is_personal = '0';
      } else if (this.$route.hash === '#employee_actions') {

        vm.type_id = '1';
        vm.is_personal = '1';
      }
      let uniqId = vm.$parent.userId;
      axios.put('emp/tasks/' + uniqId, {
        'link': vm.$refs.type_Two.link,
        'title': vm.$refs.type_Two.title,
        'description': vm.$refs.type_Two.description,
        'asset_id': vm.$refs.type_Two.uploading['fileId'].file?.id,
        'type_id': '2',
        'expire_in': vm.$refs.type_Two.time,
        'checklist_id': vm.$parent.checkListId,
      }).then((response) => {
        this.$store.dispatch('task/checkList');
        vm.$store.commit('changeModalStatus', false);

      }).catch((err) => {
        vm.handleApiError(err);
      });
    },
    updateTask() {
      let vm = this;
      if (vm.activeOne === true && vm.activeTwo === true) {
        vm.updateTypeOne();
        vm.updateTypeTwo();
      }
      if (vm.activeOne === true && vm.activeTwo === false) {
        vm.updateTypeOne();
      }
      if (vm.activeOne === false && vm.activeTwo === true) {
        vm.updateTypeTwo();
      }
    },
  },
  mixins: [helpers],
  components: {
    modal, CustomInput, validForm, typeOne, typeTwo,
  },
};
</script>
