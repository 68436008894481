<template >
  <div >
    <div class="loading-full">
    <div class="logo">
      <div class="img"></div>
    </div>
  </div>
  </div>

</template>
<script>

  export default {
    name: 'loading',

  };
</script>
