export default {
  namespaced: true,
  state: {
    list: null,
    checkList: null,
    typeList: null,

  },

  mutations: {
    set_List(state, list) {
      state.list = list;
    },
    set_check_List(state, checkList) {
      state.checkList = checkList;
    },
    set_type_List(state, typeList) {
      state.typeList = typeList;
    },

  },
  actions: {
    taskList({commit}){
      return new Promise((resolve, reject) => {
        axios.get ('emp/tasks' ).then(function(response) {
          commit('set_List', response.data.data);
          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });

    },
    checkList({commit}){
      return new Promise((resolve, reject) => {
        axios.get ('emp/check_lists' ).then(function(response) {
          commit('set_check_List', response.data.data);
          resolve();
        }).catch(function(error) {
          reject(error);
        });
      });


    },
    typeList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('emp/types').then(function (response) {
          commit('set_type_List', response.data.data);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    }


  },
};
