<template>
  <div class="role-component">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <valid-form :request="add" class="row">
          <div class="col-lg-10 col-md-10 ">
            <custom-input
                ref="role"
                v-model="role"
                :label="$i18n.t('team.roleComponent.roleTitle')"
                inputName="role"
                rules="required"
            />
          </div>
          <div class="col-lg-2 col-md-2 ">

            <button class="gray">
              <span class="icon icon-add"></span>
            </button>
          </div>
        </valid-form>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="row role-list" v-for="role in parentData.roleInfoList " :key="role.id" @click="setCurrent(role.id)">
              <div class="col-md-5 col-lg-5">
                <div class="right" @click="selectedItem()" >
                  <router-link tag="span" :to="{name:'team.index', params: {id: role.id},hash: '#role'}"  >{{role.title}}</router-link>
                </div>
              </div>
              <div class="col-md-7 col-lg-7 " v-bind:class="{'selected': current === role.id}" @click="selectedItem()">
                <router-link tag="div" class="left" :to="{name:'team.index', params: {id: role.id},hash: '#role'}"  >
                  <span class="icon icon-active-role" v-if="current === role.id"></span>
                  <span class="icon icon-profile"   v-else ></span>
                  <span    class="icon icon-edit"></span>
                  <span   class="icon icon-trash"  @click="question=true" ></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 left-col">
        <div class="row auto-margin" >
          <showPermission v-for="permission in detailPermission"  :key="permission.id" :permission="permission"></showPermission>
        </div>
      </div>
    </div>
    <question v-if="question" @confirmed="remove()" ref="RemoveQuestion"  :content=" $i18n.t('common.removeText')" :remove="$i18n.t('common.yes')" ></question>


  </div>
</template>
<script>

import showPermission from "./showPermission";
import question from "../../partials/form/question";
import helpers from "../../../../mixin/helpers";
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";

export default {
  name: 'roleComponent',
  data() {
    return {
      role: '',
      current: null,
      showError: false,
      question: false,
      actionRole: [],
    };
  },
  created() {
    this.showPermission();
  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.question = val;
      }
    }
  },
  computed: {
    parentData(){
      return this.$parent.$parent.$parent;
    },
    detailPermission() {
      let vm = this;
      let uniqId = vm.$route.params.id;
      if(uniqId){
        return this.$store.state.company.permission
      }
    },
  },

  methods: {
    setCurrent(id) {
      this.current = id;
    },
    selectedItem(){
      this.parentData.selected=true;
      let select = _.filter(this.$store.state.company.roleList,['id',this.$route.params.id])
      this.actionRole=_.forEach(select, function(value, key) {
        return value.action_role;
      });
      let pluck=_.map((this.actionRole[0] ||{}).action_role, 'action_id');
      this.$store.commit('company/set_action_role',pluck);
    },

    remove(){
      let vm = this;
      let uniqId = vm.$route.params.id;
      axios.delete('emp/company/roles/' + uniqId).then((res) => {
        vm.$store.commit('changeModalStatus', false);
        vm.$toast.success({title: vm.$i18n.t('team.successRemove')});

      }).catch((err) => {
        vm.handleApiError(err)
      });
    },
    showPermission(){
      let vm=this;
      vm.$store.dispatch('company/permission').then(() => {}).catch(() => {})
    },
    add() {
      let vm = this;
      axios.post('emp/company/roles', {
        'title':vm.role,
        'action_id':[147]
      }).then((res) => {
        vm.$toast.success({title: vm.$i18n.t('team.roleComponent.success')});
      }).catch((err) => {
        vm.handleApiError(err)
      })
    },
  },
  mixins: [helpers],
  components: {
    CustomInput, validForm,question,showPermission
  }
};
</script>
