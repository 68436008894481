export default {
  namespaced: true,
  state: {
    teamMember: null,
  },

  mutations: {
    setTeamList(state, teamMember) {
      state.teamMember = teamMember;
    },
  },
  actions: {
    teamList({commit}) {
      return new Promise((resolve, reject) => {
        axios.get ('emp/teams').then(function(response) {
          commit('setTeamList', response.data.data);
          resolve();
        }).catch(function(error) {

          reject(error);
        });
      });
    },
  },
};
