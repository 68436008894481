<template>
  <modal :classes="'add-task'">
    <div class="header" slot="title">
      <p>{{ $i18n.t('setting.task.create') }}</p>
    </div>
    <div slot="body">
      <loadData v-if="$store.state.application.loadings.task.isActive"></loadData>

      <div class="row"  :class="{'blur':$store.state.application.loadings.task.isActive}">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div class="type-box" v-for="item in typeList" :key="item.id">
            <input type="checkbox" :name="item.title" v-model="checkedNames" :value="item.id"
                   :checked="checked(item.id)">
            <label>{{ item.title }}</label>

          </div>
          <valid-form :request="addTask" ref="form">
            <typeOne @event="addTypeOne" v-if="one" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" ref="type_one"></typeOne>

            <typeTwo @input="addTypeTwo" v-if="two" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                     ref="type_Two"></typeTwo>

            <div class="col-lg-12 col-md-12 action-btn">
              <button class="blue">{{ $i18n.t('setting.add.submit') }}</button>
            </div>
          </valid-form>
        </div>
        <br>

      </div>
    </div>
  </modal>
</template>
<script>
import loadData from '../../partials/form/loadData'

import modal from '../../partials/modal.vue';
import helpers from "../../../../mixin/helpers";
import CustomInput from "../../partials/form/customInput.vue";
import validForm from "../../partials/form/valid-form.vue";
import typeTwo from "./typeTwo";
import typeOne from "./typeOne";

export default {
  name: 'add_task',
  data() {
    return {
      activeOne: false,
      activeTwo: false,
      type: '',
      checkedNames: []

    };
  },
  created() {
    this.getType();
    if (this.checkedNames.indexOf(1) === -1) {
      this.checkedNames.push(1)
      this.activeOne=true
    }
  },

  computed: {
    typeList() {
      return this.$store.state.task.typeList
    },
  one() {
      return this.activeOne
    },
  two() {
      return this.activeTwo
    },



  },
  methods: {
    getType(){
      let vm=this;
      vm.$store.state.application.loadings.task.isActive = true;
      axios.get('emp/types').then(function (response) {
        vm.$store.state.task.typeList= response.data.data;
        vm.$store.state.application.loadings.task.isActive = false;
      }).catch(function (error) {
      });

    },
    checked(id) {
      let vm = this;
      if (vm.checkedNames.indexOf(1) !== -1 && vm.checkedNames.indexOf(2) === -1) {
        vm.activeOne = true;
      }
      if ( vm.checkedNames.indexOf(2) !== 1) {
        vm.activeTwo = false;vm.activeOne = true;

      }
       if(vm.checkedNames.indexOf(1) === -1 && vm.checkedNames.indexOf(2) !== -1){
        vm.activeOne = false;
         vm.activeTwo = true;
       }
       if(vm.checkedNames.indexOf(1) !== -1 && vm.checkedNames.indexOf(2) !== -1){
        vm.activeOne = true;
         vm.activeTwo = true;

       }


    },
    addTypeOne() {
      let vm = this;
      axios.post('emp/tasks', {
        'link': vm.$refs.type_one.link,
        'title': vm.$refs.type_one.title,
        'description': vm.$refs.type_one.description,
        'asset_id': vm.$refs.type_one.uploading['fileId'].file?.id,
        'type_id': '1',
        'expire_in': vm.$refs.type_one.time,
        'checklist_id': vm.$parent.checkListId,
      }).then(() => {
        vm.$store.dispatch('task/checkList');
        vm.$store.commit('changeModalStatus', false);
      }).catch((err) => {
        vm.handleApiError(err)
      })
    },
    addTypeTwo() {
      let vm = this;
      axios.post('emp/tasks', {
        'link': vm.$refs.type_Two.link,
        'title': vm.$refs.type_Two.title,
        'description': vm.$refs.type_Two.description,
        'asset_id': vm.$refs.type_Two.uploading['fileId'].file?.id,
        'type_id': '2',
        'expire_in': vm.$refs.type_Two.time,
        'checklist_id': vm.$parent.userId,
      }).then(() => {
        vm.$store.dispatch('task/checkList');
        vm.$store.commit('changeModalStatus', false);
      }).catch((err) => {
        vm.handleApiError(err)
      })
    },
    addTask() {
      let vm = this;
      if (vm.activeOne===true&&vm.activeTwo===true) {
        vm.addTypeTwo()
        vm.addTypeOne()
      }
       if(vm.activeOne===true &&vm.activeTwo===false){
        vm.addTypeOne()
      }
       if(vm.activeOne===false &&vm.activeTwo===true) {
         vm.addTypeTwo()
      }
    }
  },
  mixins: [helpers],
  components: {
    modal, CustomInput, validForm, typeOne, typeTwo,loadData
  }
};
</script>
