<template>
  <div>
    <div class="row topBar" id="topBar">
      <div class="col-lg-6 col-md-6 col-xs-2  col-sm-6 right-align">
        <div class="logo" @click="toggle=!toggle"></div>
        <div class="toggle-icon icon icon-menu"  @click="$store.commit('Toggle')"></div>
        <div class="show-company" v-if="toggle" @click="toggle=false">
          <a href="https://ats.hire.camp" class="pointer" target="_blank" > <p>Hire.Camp / ATS</p> </a>
          <a href="https://www.hros.hire.camp" class="pointer" target="_blank"><p>Hire.Camp / HROS  </p> </a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-10  col-sm-6 left-align">
        <div class="user-hyrecamp" v-if="$store.state.auth.userExist">
          <div class="user">
            <span>{{ user.name }}</span>
            <span class="position" v-if="jobUser">{{jobUser.title}}</span>
            <span class="position" v-else>--</span>
          </div>
          <img class="avatar" v-if="user.avatar" :src="user.avatar" @click="showProfile=!showProfile">
          <div class="avatar-load" v-else  @click="showProfile=!showProfile"></div>

        </div>

        <div class="show-profile" v-if="showProfile" @click="showProfile=false">
          <router-link tag="p" :to="{name: 'user.edit'}" class="pointer" >  {{ $i18n.t('common.edit') }}</router-link>
          <p @click="question=true">  {{ $i18n.t('common.logOut') }}</p>
        </div>
      </div>

    </div>
    <question v-if="question" @confirmed="logout()" ref="logOut"
              :content=" $i18n.t('common.logOutContent') "
              :remove="$i18n.t('common.yes')"></question>
  </div>

</template>

<script>
import question from '../../partials/form/question';

export default {
  name: 'home',
  data(){
    return{
      toggle:false,
      showProfile:false,
      question:false
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {}
    },
    jobUser(){
      let jobPosition= _.filter(this.$store.state.project.jobList, ['id',this.user.job_id])
      return jobPosition[0]

    }
  },

  methods:{
    logout() {
      let vm = this;
      axios.post('oauth/logout', {
        'grant_type': 'password',
        'client_id': '2',
        'username': vm.user.mobile,
        'client_secret': 'WsR5JKy5DJpW1xFFFBf4pgnNNxmBIDkHQRzKFxuQ',

      }).then((res) => {
        vm.$store.dispatch('auth/fetchUser')
        this.$store.state.auth.user=null;
        this.$store.state.auth.userExist=false;
        console.log(this.$store.state.auth)
        let tabs= vm.$store.state.application.sidebarItem || {};
        vm.$router.push({name: 'auth.loginInit'});
        return _.filter(tabs, (item) => {
          if (item.group === 'user') {
            return false;
          }
        });

      }).catch((err) => {
        vm.handleApiError(err)
        // vm.$toast.error({message: vm.$i18n.t('auth.loginFailed')});

      });
    }
    },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.question = val;
      }
    },
  },
  components:{
    question
  }
}
</script>
