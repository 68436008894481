<template>
  <div class="panel-container setting-page">
    <div class="row padding-box">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab">

        <tabs class="row">
          <div slot="button">
            <button class="blue create" v-if="showButton" @click="addAction=true"><span class="icon icon-add"></span>
              {{ $i18n.t('setting.category') }}
            </button>
          </div>
          <div class="col-lg-7 col-md-7 col-xs-12 col-sm-12">
            <tab :name="$i18n.t('setting.companyAction')" type="company_actions" :selected="selected.company_actions">
              <loadData v-if="$store.state.application.loadings.task.isActive"></loadData>
              <div class="color-title" :class="{'blur blur-height':$store.state.application.loadings.task.isActive}"
                   v-for="check in companyTask" :key="check.id">
                <div class="heading-box">
                  <div class="right-col">
                    <span class="icon heading icon-minimize">{{ check.title }}</span>
                  </div>
                  <div class="left-col" v-for="owner in ownerName[check.owner_id]||[]"
                       :key="owner.id">
                    <!--<img class="avatar" v-if="owner.avatar" :src="owner.avatar">-->
                    <span class="icon icon-bell pointer"></span>
                    <span class="icon icon-more-gray pointer" @click="getCheckListId(check.id)"
                          v-popover.bottom="{ name: 'editSetting' }"></span>
                    <div class="avatar-load"></div>
                    <button class="gray" @click="addTask=true">
                      <span class="icon icon-add" @click="getCheckListId(check.id)"></span>
                    </button>
                  </div>
                </div>
                <div @click="getCheckListId(check.id)">
                  <singleTask v-for="task in check.tasks.data" :task="task" :key="task.id"></singleTask>

                </div>
              </div>
            </tab>
          </div>
          <div class="col-lg-7 col-md-7 col-xs-12 col-sm-12">
            <tab :name="$i18n.t('setting.employeeAction')" type="employee_actions"
                 :selected="selected.employee_actions">
              <loadData v-if="$store.state.application.loadings.task.isActive"></loadData>
              <div class="color-title" :class="{'blur blur-height':$store.state.application.loadings.task.isActive}"
                   v-for="check in personalTask" :key="check.id">

                <div class="heading-box">
                  <div class="right-col">
                    <span class="icon heading icon-minimize" @click="zizi=!zizi">{{ check.title }}</span>
                  </div>
                  <div class="left-col" v-for="owner in ownerName[check.owner_id]||[]"
                       :key="owner.id">
                    <!--<img class="avatar" v-if="owner.avatar" :src="owner.avatar">-->
                    <span class="icon icon-bell pointer"></span>
                    <span class="icon icon-more-gray pointer" @click="getCheckListId(check.id)"
                          v-popover.bottom="{ name: 'editSetting' }"></span>
                    <div class="avatar-load"></div>
                    <button class="gray" @click="getUpdate(check.id)">
                      <span class="icon icon-add" @click="getCheckListId(check.id)"></span>
                    </button>

                  </div>
                </div>
                <div @click="getCheckListId(check.id)">
                  <singleTask v-for="task in check.tasks.data" :task="task" :key="task.id"></singleTask>
                </div>
              </div>
            </tab>
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <tab :name="$i18n.t('setting.welcome')" type="welcome_message" :selected="selected.welcome_message">
              <welcomeComponent class="row"></welcomeComponent>

            </tab>
          </div>
          <div class="col-lg-7 col-md-8 col-xs-12 col-sm-12">
            <tab :name="$i18n.t('setting.basicInfo')" type="basic_info" :selected="selected.basic_info">
              <basicInfo></basicInfo>
            </tab>
          </div>


        </tabs>
        <popover name="editSetting">
          <div class="info-profile">
            <p class="edit" @click="update">
              {{ $i18n.t('common.edit') }}
            </p>
            <p class="remove" @click="question=true">{{ $i18n.t('common.remove') }}</p></div>
        </popover>
      </div>

    </div>
    <addTask v-if="addTask"></addTask>
    <editTask v-if="editTask"></editTask>
    <addAction v-if="addAction"></addAction>
    <editAction v-if="editCheckList"></editAction>
    <question v-if="question" @confirmed="remove()" ref="RemoveQuestion"
              :content=" checkList?$i18n.t('setting.add.removeChecklist'):$i18n.t('setting.add.removeTask')"
              :remove="$i18n.t('common.yes')"></question>

  </div>


</template>
<script>
import loadData from '../partials/form/loadData';
import question from '../partials/form/question';
import singleTask from './partials/singleTask';
import addTask from './partials/addTask';
import editTask from './edit/editTask';
import addAction from './partials/addAction';
import editAction from './partials/editAction';
import welcomeComponent from './partials/welcomeComponent';
import basicInfo from './partials/basicInfo';
import helpers from '../../../mixin/helpers';

export default {
  name: 'setting',
  data() {
    return {
      zizi: false,
      checkList: false,
      editCheckList: false,
      addTask: false,
      editTask: false,
      question: false,
      checkListId: '',
      userId: '',
      addAction: false,
      selected: {
        basic_info: false,
        company_actions: false,
        employee_actions: false,
        welcome_message: false,

      },
    };
  },
  mixins: [helpers],
  created() {
    this.tab();
    this.$store.dispatch('task/checkList');
  },
  watch: {
    '$store.state.application.isOpenModal'(val) {
      if (!val) {
        this.addAction = val;
        this.editCheckList = val;
        this.editTask = val;
        this.addTask = val;
        this.question = val;
      }
    },
  },
  computed: {
    randomColor() {
      let colors = ['blue', 'green', 'yellow'];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    personalTask() {
      let task = _.filter(this.$store.state.task.checkList, ['is_personal', 1]);
      return task;
    },
    ownerName() {
      let item = _.filter(this.$store.state.team.teamMember, 'id');
      let owner = _.groupBy(item, 'id');
      return owner;
    },
    companyTask() {
      let task = _.filter(this.$store.state.task.checkList, ['is_personal', 0]);
      return task;
    },
    showButton() {
      let company = this.$route.hash === '#company_actions';
      let employee = this.$route.hash === '#employee_actions';
      return company || employee || this.$route.hash === '';

    },
  },
  methods: {
    getCheckListId(id) {
      this.userId = id;
      this.checkListId = id;
      this.checkList = true;
    },

    getUpdate(id) {
      this.checkListId = id;
      this.addTask = true;
    },
    tab() {
      if (this.$route.hash === '#company_actions') {
        return this.selected.company_actions = true;
      } else if (this.$route.hash === '#basic_info') {
        return this.selected.basic_info = true;
      } else if (this.$route.hash === '#welcome_message') {
        return this.selected.welcome_message = true;
      } else if (this.$route.hash === '#employee_actions') {
        return this.selected.employee_actions = true;
      } else {
        this.selected.company_actions = true;
      }
    },
    remove() {
      let vm = this;
      let uniqId = vm.userId;
      if (vm.checkList) {
        axios.delete('emp/check_lists/' + uniqId).then(() => {
          vm.$store.commit('changeModalStatus', false);
          this.$store.dispatch('task/checkList');
          vm.$toast.success({title: vm.$i18n.t('setting.checklistRemove')});
        }).catch((err) => {
          vm.handleApiError(err);
        });
      } else {
        axios.delete('emp/tasks/' + uniqId).then(() => {
          vm.$store.commit('changeModalStatus', false);
          this.$store.dispatch('task/checkList');
          vm.$toast.success({title: vm.$i18n.t('project.successRemoveTask')});
        }).catch((err) => {
          vm.handleApiError(err);
        });
      }
    },
    update() {
      let vm = this;
      if (vm.checkList) {
        this.editCheckList = true;
      } else {
        this.editTask = true;
      }
    },
  },

  components: {addAction, singleTask, question, welcomeComponent, basicInfo, loadData, editAction, editTask, addTask},

};
</script>
