import Vue from 'vue';
Vue.filter(
    'persianNumbers',
    require('./persianNumbers').default,
);
Vue.filter(
    'slice',
    require('./slice').default,
);
Vue.filter(
    'strLimit',
    require('./strLimit').default,
);
Vue.filter(
    'jalali',
    require('./jalaliWithOutHours').default,
);
Vue.filter(
    'jalaliFormat',
    require('./jalaliFormat').default,
);

