<template>
  <div class="modal"  @keydown.esc="closeModal()">
    <transition name="fade" >
      <div class="modal-mask" @keydown.esc="closeModal()">
        <div class="modal-wrapper">
          <div class="modal-container" :class="classes">
            <div class="modal-header">
              <span class="icon icon-close" @click="closeModal()"></span>
              <span ><slot name="title"></slot></span>

            </div>
              <div class="modal-body">
                <div class="contains">
                  <slot name="body">
                  </slot>
                </div>
              </div>

          </div>
        </div>
      </div>
    </transition>
  </div>

</template>
<script>
export default {
  name: 'modal',

  data() {
    return {
      closeModalContent: true,
    };
  },
  props: ['classes'],

  mounted() {
    this.$store.commit('changeModalStatus', true);
    this.closeModalContent = false;
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit('close');
      }
    });
  },
  methods: {
    closeModal() {
      this.$store.commit('changeModalStatus', false);
      this.$emit('close');

    },
  },
};
</script>

