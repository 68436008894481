export default {
    state: {
        isOpenModal: false,
        employee:false,
        user:false,
        sidebarItem: [
            {
                link: {name: 'myCheckList.index'},
                icon: 'myWork-icon',
                titleTransKey: 'sidebar.myWork',
                group: 'user',
            },
            {
                link: {name: 'project.index'},
                icon: 'task-icon',
                titleTransKey: 'sidebar.task',
                group: 'employee',
            },
            {
                link: {name: 'setting.index'},
                icon: 'setting-icon',
                titleTransKey: 'sidebar.setting',
                group: 'employee',
            },
            {
                link: {name: 'team.index'},
                icon: 'team-icon',
                titleTransKey: 'sidebar.team',
                group: 'employee',
            },
        ],
        loadings: {
            auth: {
                type: 'full',
                isActive: true,
            },
            user: {
                type: 'progress',
                isActive: false,
            },
            project: {
                type: 'progress',
                isActive: false,
            },
            team: {
                type: 'progress',
                isActive: false,
            },
            task: {
                type: 'progress',
                isActive: false,
            },
        },
        sidebarToggle: true,
        mobileSidebarToggle: false,
        isClose: false,




    },
    getters: {
        LoadingFull(state) {
            return _.countBy(state.loadings, function (loading) {
                return loading.type === 'full' && loading.isActive;
            }).true !== undefined;
        },
    },
    mutations: {

        Toggle(state) {
            state.mobileSidebarToggle = !state.mobileSidebarToggle;
            state.sidebarToggle = !state.sidebarToggle;

        },

        changeModalStatus(state, status) {
            state.isOpenModal = status;
        },
        LoadingFull(state) {
            return _.countBy(state.loadings, function (loading) {
                return loading.type === 'full' && loading.isActive;
            }).true !== undefined;
        },
        loadingStatus(state, {name, status}) {
            if (!state.loadings[name]) {
                console.error("loading with name `" + name + "` does not exists in application store.")
                return;
            }
            state.loadings[name].isActive = status;
        },
    },
};
